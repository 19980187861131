import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import "./../ProductDetails/productDetails.css";

const plywoodData = [
  { size: '1525 x 1525 x 3მმ', price: 31 },
  { size: '1525 x 1525 x 4მმ', price: 33 },
  { size: '1525 x 1525 x 6მმ', price: 43 },
  { size: '1525 x 1525 x 8მმ', price: 53 },
  { size: '1525 x 1525 x 10მმ', price: 60 },
  { size: '1525 x 1525 x 12მმ', price: 74 },
  { size: '1525 x 1525 x 15მმ', price: 88 },
  { size: '1525 x 1525 x 18მმ', price: 100 },
];

function PlywoodSelector12() {
  const [selectedSize, setSelectedSize] = useState("");
  const [price, setPrice] = useState(null);

  const handleSizeChange = (event) => {
    const selected = event.target.value;
    setSelectedSize(selected);
    const selectedPlywood = plywoodData.find((item) => item.size === selected);
    setPrice(selectedPlywood ? selectedPlywood.price : null);
  };

  return (
    <div className="w-full mx-auto border-b-[1px] border-b-gray-300">
      <div className="max-w-6xl mx-auto px-4">
        <div className="w-full grid grid-cols-1 md:grid-cols-2 gap-8 h-full pb-10 bg-gray-50 p-6 rounded-lg shadow-lg">
          {/* Image Section */}
          <div className="flex justify-center items-center">
            <div className="overflow-hidden rounded-lg shadow-md">
              <img
                className="w-full h-full object-cover transform hover:scale-105 transition-transform duration-500"
                src="https://i.imgur.com/uCk1O4o.jpg"
                alt="Plywood"
              />
            </div>
          </div>

          {/* Dropdown and Price Section */}
          <div className="flex flex-col justify-center items-start gap-6 bg-white p-8 rounded-lg shadow-md">
          <h2 className="text-2xl font-semibold text-gray-800">
              ფანერა I/II ხარისხი 1525 x 1525 მმ
            </h2>
            <h2 className="text-2xl font-semibold text-gray-800">
              აირჩიეთ ფანერის სისქე
            </h2>

            <select
              onChange={handleSizeChange}
              value={selectedSize}
              className="w-full p-3 border rounded-lg bg-gray-100 hover:bg-gray-200 transition duration-300 focus:outline-none focus:ring-2 focus:ring-blue-500"
            >
              <option value="">აირჩიეთ სისქე</option>
              {plywoodData.map((item, index) => (
                <option key={index} value={item.size}>
                  {item.size}
                </option>
              ))}
            </select>

            {price !== null && (
              <div className="text-lg font-medium text-green-600">
                <h3>ფასი: {price}₾</h3>
              </div>
            )}
          </div>
        </div>

        {/* Additional Info Section */}
        <div className="my-8">
          <button className="bg-blue-600 text-white py-3 px-6 rounded-lg shadow hover:bg-blue-700 transition duration-300">
            ტექნიკური მახასიათებლები
          </button>

          <div className="my-6 p-6 bg-gray-50 rounded-lg shadow-md">
            <table className="table-auto w-full text-left border-collapse">
              <tbody>
                <tr className="border-b">
                  <td className="px-4 py-3 font-semibold">მწარმოებელი ქვეყანა</td>
                  <td className="px-4 py-3">ბელორუსია</td>
                </tr>
                <tr className="border-b">
                  <td className="px-4 py-3 font-semibold">ხის ჯიში</td>
                  <td className="px-4 py-3">არყის ხე</td>
                </tr>
                <tr>
                  <td className="px-4 py-3 font-semibold">ტენიანობა</td>
                  <td className="px-4 py-3">8 - 10 %</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PlywoodSelector12;
