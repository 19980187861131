import { Pagination3, Pagination7 } from "../../components/pageProps/shopPage/Pagination";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Breadcrumbs from "../../components/pageProps/Breadcrumbs";
import ProductInfo from "../../components/pageProps/productDetails/ProductInfo";
import { FaDownload } from "react-icons/fa";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import "./../ProductDetails/productDetails.css"

export default function Bioseptiki () {
  const [itemsPerPage, setItemsPerPage] = useState(48);
  const itemsPerPageFromBanner = (itemsPerPage) => {
    setItemsPerPage(itemsPerPage);
  };
  const location = useLocation();
  const [prevLocation, setPrevLocation] = useState("");
  const [productInfo, setProductInfo] = useState([]);
  const [current, setCurrent] = useState(0);

  const images = [
   "https://i.imgur.com/UkxITxj.jpg",
   "https://i.imgur.com/eCfyv5u.jpg"
];
  const nextSlide = () => {
    setCurrent(current === images.length - 1 ? 0 : current + 1);
  };

  const prevSlide = () => {
    setCurrent(current === 0 ? images.length - 1 : current - 1);
  };
  return (
    <div className="w-full mx-auto border-b-[1px] border-b-gray-300">
    <div className="max-w-container mx-auto px-4">
      <div style={{marginTop: '60px'}} className="xl:-mt-10 -mt-7">
        {/* <Breadcrumbs title="" prevLocation={prevLocation} /> */}
      </div>
      <div className="w-full grid grid-cols-1 md:grid-cols-2 xl:grid-cols-6 gap-4 h-full -mt-5 xl:-mt-8 pb-10 bg-gray-100 p-4">
          <div className="h-full xl:col-span-2">
            <div className='butts' >
              <button onClick={prevSlide} style={{background: 'none', border: '0'}}><FontAwesomeIcon style={{width: '30px', height: '30px'}} icon={faChevronLeft} /></button>
                <img 
                  className="w-full h-full"
                  src={images[current]}
                  alt={productInfo.img}
                />
              <button onClick={nextSlide}style={{background: 'none', border: '0'}}><FontAwesomeIcon style={{width: '30px', height: '30px'}} icon={faChevronRight} /></button>
            </div>
        </div>
        <div className="h-full w-full md:col-span-2 xl:col-span-4 xl:px-4 flex flex-col gap-6 justify-center">
        <div className="flex flex-col gap-5">
            <h2 className="text-4xl font-semibold">ბიოლოგიური გამწმენდი სადგური</h2>
            <p className="text-1xl font-semibold">
            ბიოლოგიური გამწმენდი  სადგურები  „KANN“   ( ბიოსეპტიკი ) განკუთვნილია საყოფაცხოვრებო ჩამდინარე წყლების გასაწმენდათ: კოტეჯების, აგარაკების, სახლების, ცალკეული შენობების, ინფრასტრუქტურის ობიექტების და სხვა დეცენტრალიზებული საკანალიზაციო სისტემების მქონე ობიექტებისათვის. 
            <br />
            <br />
            კერძო სახლებში დატვირთვის გამოთვლა  ხორციელდება სულადობის მიხედვით,  დღე-ღამეში ერთ სულზე საშუალოდ 150-200 ლიტრი  მოხმარებული წყალი. გამომდინარე მოსალოდნელი დატვირთვიდან ჩვენ გთავაზობთ შემდეგი სიმძლავრის  და ღირებულების სადგურებს:
            <br />
            <br />
            „ეკო 6“ ( 6 სულზე ) 4500ლ <br />
            „X - 5 ( ვერტიკალური )“ ( 6 სულზე ) 5000ლ <br />
            „ულტრა 8“ ( 8 სულზე ) 7000ლ <br />
            „ულტრა 10“ ( 10 სულზე ) 8000ლ <br />
            „ულტრა 15“ ( 15 სულზე ) 9000ლ <br />
            „X - 25 ( ვერტიკალური ) “ ( 25 სულზე ) 10000ლ <br />

            </p>
            <p className="text-base text-green-600 font-medium">მარაგშია</p>
          </div>
        </div>
      </div>
      </div>

      <div>
        <div className=" space-x-4  pt-4">
            <button className="bg-blue-500 text-white py-2 px-4  focus:outline-none">ტექნიკური მახასიათებლები</button>
        </div>
        <div className="my-4">
          <div>
            
            <table className="table-auto w-full">
              <tbody>
                <tr className="bg-gray-100">
                  <td className="border px-4 py-2 font-semibold">მწარმოებელი ქვეყანა</td>
                  <td className="border px-4 py-2">ბელორუსია</td>
                </tr>
                <tr className="bg-gray-100">
                  <td className="border px-4 py-2 font-semibold">სიგრძე</td>
                  <td className="border px-4 py-2">2500-6500მმ (დამოკიდებულია მოდელზე)</td>
                </tr>
                <tr className="bg-gray-100">
                  <td className="border px-4 py-2 font-semibold">წონა</td>
                  <td className="border px-4 py-2">125-650კგ (დამოკიდებულია მოდელზე)</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        {/* <div className="my-4">
          {tabs.map((tab) => (
            <div
              key={tab.id}
              className={activeTab === tab.id ? "" : "hidden"}
            >
              {tab.id === "Fiche Technique" && productInfo.ficheTech ? (
                <div>
                  <table className="table-auto w-full">
                    <tbody>
                      {productInfo.ficheTech.map((row) => (
                        <tr key={row.label} className="bg-gray-100">
                          <td className="border px-4 py-2 font-semibold">
                            {row.label}
                          </td>
                          <td className="border px-4 py-2">{row.value}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <div className="my-4 flex justify-end">
                    
                  </div>
                </div>
              ) : (
                <p>{tab.content}</p>
              )}
            </div>
          ))}
        </div> */}
      </div>
    </div>
  );
};

// import { Pagination3, Pagination7 } from "../../components/pageProps/shopPage/Pagination";
// import React, { useEffect, useState } from "react";
// import { useLocation } from "react-router-dom";
// import Breadcrumbs from "../../components/pageProps/Breadcrumbs";
// import ProductInfo from "../../components/pageProps/productDetails/ProductInfo";
// import { FaDownload } from "react-icons/fa";
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
// import "./../ProductDetails/productDetails.css"

// export default function Bioseptiki () {
//   const [itemsPerPage, setItemsPerPage] = useState(48);
//   const itemsPerPageFromBanner = (itemsPerPage) => {
//     setItemsPerPage(itemsPerPage);
//   };
//   const location = useLocation();
//   const [prevLocation, setPrevLocation] = useState("");
//   const [productInfo, setProductInfo] = useState([]);
//   const [current, setCurrent] = useState(0);

//   const images = [
//    "https://i.imgur.com/UkxITxj.jpg",
//    "https://i.imgur.com/eCfyv5u.jpg"
// ];
//   const nextSlide = () => {
//     setCurrent(current === images.length - 1 ? 0 : current + 1);
//   };

//   const prevSlide = () => {
//     setCurrent(current === 0 ? images.length - 1 : current - 1);
//   };
//   return (
//     <div className="w-full mx-auto border-b-[1px] border-b-gray-300">
//     <div className="max-w-container mx-auto px-4">
//       <div style={{marginTop: '60px'}} className="xl:-mt-10 -mt-7">
//         {/* <Breadcrumbs title="" prevLocation={prevLocation} /> */}
//       </div>
//       <div className="w-full grid grid-cols-1 md:grid-cols-2 xl:grid-cols-6 gap-4 h-full -mt-5 xl:-mt-8 pb-10 bg-gray-100 p-4">
//           <div className="h-full xl:col-span-2">
//             <div className='butts' >
//               <button onClick={prevSlide} style={{background: 'none', border: '0'}}><FontAwesomeIcon style={{width: '30px', height: '30px'}} icon={faChevronLeft} /></button>
//                 <img 
//                   className="w-full h-full"
//                   src={images[current]}
//                   alt={productInfo.img}
//                 />
//               <button onClick={nextSlide}style={{background: 'none', border: '0'}}><FontAwesomeIcon style={{width: '30px', height: '30px'}} icon={faChevronRight} /></button>
//             </div>
//         </div>
//         <div className="h-full w-full md:col-span-2 xl:col-span-4 xl:px-4 flex flex-col gap-6 justify-center">
//         <div className="flex flex-col gap-5">
//             <h2 className="text-4xl font-semibold">ბიოლოგიური გამწმენდი სადგური</h2>
//             <p className="text-1xl font-semibold">
//             ბიოლოგიური გამწმენდი  სადგურები  „KANN“   ( ბიოსეპტიკი ) განკუთვნილია საყოფაცხოვრებო ჩამდინარე წყლების გასაწმენდათ: კოტეჯების, აგარაკების, სახლების, ცალკეული შენობების, ინფრასტრუქტურის ობიექტების და სხვა დეცენტრალიზებული საკანალიზაციო სისტემების მქონე ობიექტებისათვის. 
//             <br />
//             <br />
//             კერძო სახლებში დატვირთვის გამოთვლა  ხორციელდება სულადობის მიხედვით,  დღე-ღამეში ერთ სულზე საშუალოდ 200 ლიტრი  მოხმარებული წყალი. გამომდინარე მოსალოდნელი დატვირთვიდან ჩვენ გთავაზობთ შემდეგი სიმძლავრის  და ღირებულების სადგურებს:
//             <br />
//             <br />
//             „ეკო 4“ ( 4 სულზე ) 4000ლ<br />
//             „ეკო 6“ ( 6 სულზე ) 4500ლ <br />
//             „X - 5 ( ვერტიკალური )“ ( 5 სულზე ) 5000ლ <br />
//             „ულტრა 8“ ( 8 სულზე ) 7000ლ <br />
//             „ულტრა 10“ ( 10 სულზე ) 8000ლ <br />
//             „ულტრა 15“ ( 15 სულზე ) 9000ლ <br />
//             15 სულზე ზემოთ -   დეტალები და ფასი შეთანხმებით
//             </p>
//             <p className="text-base text-green-600 font-medium">მარაგშია</p>
//           </div>
//         </div>
//       </div>
//       </div>

//       <div>
//         <div className=" space-x-4  pt-4">
//             <button className="bg-blue-500 text-white py-2 px-4  focus:outline-none">ტექნიკური მახასიათებლები</button>
//         </div>
//         <div className="my-4">
//           <div>
            
//             <table className="table-auto w-full">
//               <tbody>
//                 <tr className="bg-gray-100">
//                   <td className="border px-4 py-2 font-semibold">მწარმოებელი ქვეყანა</td>
//                   <td className="border px-4 py-2">ბელორუსია</td>
//                 </tr>
//                 <tr className="bg-gray-100">
//                   <td className="border px-4 py-2 font-semibold">სიგრძე</td>
//                   <td className="border px-4 py-2">2500-6500მმ (დამოკიდებულია მოდელზე)</td>
//                 </tr>
//                 <tr className="bg-gray-100">
//                   <td className="border px-4 py-2 font-semibold">წონა</td>
//                   <td className="border px-4 py-2">125-650კგ (დამოკიდებულია მოდელზე)</td>
//                 </tr>
//               </tbody>
//             </table>
//           </div>
//         </div>
//         {/* <div className="my-4">
//           {tabs.map((tab) => (
//             <div
//               key={tab.id}
//               className={activeTab === tab.id ? "" : "hidden"}
//             >
//               {tab.id === "Fiche Technique" && productInfo.ficheTech ? (
//                 <div>
//                   <table className="table-auto w-full">
//                     <tbody>
//                       {productInfo.ficheTech.map((row) => (
//                         <tr key={row.label} className="bg-gray-100">
//                           <td className="border px-4 py-2 font-semibold">
//                             {row.label}
//                           </td>
//                           <td className="border px-4 py-2">{row.value}</td>
//                         </tr>
//                       ))}
//                     </tbody>
//                   </table>
//                   <div className="my-4 flex justify-end">
                    
//                   </div>
//                 </div>
//               ) : (
//                 <p>{tab.content}</p>
//               )}
//             </div>
//           ))}
//         </div> */}
//       </div>
//     </div>
//   );
// };

