import {
  createBrowserRouter,
  RouterProvider,
  Outlet,
  createRoutesFromElements,
  Route,
  ScrollRestoration,
} from "react-router-dom";
import Safexuri from "./pages/xismasala/muxa/muxa";
import PlywoodSelector122 from "./pages/xismasala/didiformati";
import Footer from "./components/home/Footer/Footer";
import FooterBottom from "./components/home/Footer/FooterBottom";
import Header from "./components/home/Header/Header";
import HeaderBottom from "./components/home/Header/HeaderBottom";
import SpecialCase from "./components/SpecialCase/SpecialCase";
import About from "./pages/About/About";
import SignIn from "./pages/Account/SignIn";
import SignUp from "./pages/Account/SignUp";
import Cart from "./pages/Cart/Cart";

import Contact from "./pages/Contact/Contact";
import Home from "./pages/Home/Home";
import Journal from "./pages/Journal/Journal";
import Offer from "./pages/Offer/Offer";
import Payment from "./pages/payment/Payment";
import ProductDetails from "./pages/ProductDetails/ProductDetails";
import Shop from "./pages/Shop/Shop";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Fanera from "./pages/xismasala/xismasala";
import Aksesuarebi from "./pages/bioseptiki/aksesuarebi";
import Laminati from "./pages/xismasala/laminati";
import Fanera34 from "./pages/xismasala/34";
import Keramograniti from "./pages/keramika/keramograniti";
import Klinkeri from "./pages/keramika/klinkeri";
import Sidebarr from "./components/sidebar/sidebar";
import { ProductDetails2 } from "./pages/ProductDetails/ProductDetails";
import Dvpone from "./pages/xismasala/dvp/dvpone";
import Dvptwo from "./pages/xismasala/dvp/dvptwo";
import Bioseptiki from "./pages/bioseptiki/bioseptiki";
import Septic from "./pages/bioseptiki/septic";
import Osb from "./pages/xismasala/osb/oesbeebi";
import Didi from "./pages/xismasala/didiformati";
import PlywoodSelector23 from "./pages/xismasala/23";
import PlywoodSelector12 from "./pages/xismasala/12";
import Cximgamkopi from "./pages/bioseptiki/cximgamkopi";
import Cximdamcheri from "./pages/bioseptiki/cximdamcheri";
import Pompebi from "./pages/bioseptiki/pompebi";
import Luki from "./pages/bioseptiki/lukebi";
import Cha from "./pages/bioseptiki/chebi";

const Layout = () => {
  return (
    <div>
      <ToastContainer
        position="top-right"
        autoClose={1000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      /> 
      
      {/* <Header /> */}
      <HeaderBottom />
      <SpecialCase />
      <ScrollRestoration />
      <Outlet />
      <Footer />
      <FooterBottom />
    </div>
  );
};
const router = createBrowserRouter(
  createRoutesFromElements(
    <Route>
      <Route path="/" element={<Layout />}>
        {/* ==================== Header Navlink Start here =================== */}
        <Route index element={<Home />}></Route>
        <Route path="/septic" element={<Septic/>} ></Route>
        <Route path="/shop" element={<Shop />}></Route>
        <Route path="/largeplywood" element={<PlywoodSelector122 /> } />
        <Route path="plywood" element={<Fanera /> }></Route>
        <Route path="/plywood12" element={<PlywoodSelector12 />}></Route>
        <Route path="/plywood23" element={<PlywoodSelector23 />}></Route>
        <Route path="/plywood34" element={<Fanera34 />}></Route>
        <Route path="/dvpone" element={<Dvpone />}></Route>
        <Route path="/dvptwo" element={<Dvptwo />}></Route>
        <Route path="/laminati" element={<Laminati />}></Route>
        <Route path="/keramograniti" element={<Keramograniti />}></Route>
        <Route path="/klinkeri" element={<Klinkeri />}></Route>
        <Route path="/largeplywood" element={<Didi />}></Route>
        <Route path="/about" element={<About />}></Route>
        <Route path="/contact" element={<Contact />}></Route>
        <Route path="/mukhissapekhuri" element={<Safexuri />} />
        <Route path="/septic/ckhimdamcheri" element={<Cximdamcheri />} />
        <Route path="septic/ckhimgamkhopi" element={<Cximgamkopi />} />
        <Route path="/septicaccessories" element={<Aksesuarebi />} />
        <Route path="/journal" element={<Journal />}></Route>
        <Route path="/airpumps" element={<Pompebi />} />
        <Route path="/septicaccessories/luqi" element={ <Luki /> } />
        <Route path="/septicaccessories/cha" element={<Cha />} />
        {/* ==================== Header Navlink End here ===================== */}
        <Route path="/category/:category" element={<Offer />}></Route>
        <Route path="/product/:_id" element={<ProductDetails />}></Route>
        <Route path="/product2/:_id" element={<ProductDetails2 />}></Route>
        <Route path="/cart" element={<Cart />}></Route>
        <Route path="/paymentgateway" element={<Payment />}></Route>
        <Route path="/bioseptic" element={<Bioseptiki />}></Route>
        <Route path="/Osb" element={<Osb />}></Route>
      </Route>
      <Route path="/signup" element={<SignUp />}></Route>
      <Route path="/signin" element={<SignIn />}></Route>
    </Route>
  )
);

function App() {
  return (
    <div className="font-bodyFont">
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
