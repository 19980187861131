import {
  spfOne,
  spfTwo,
  spfThree,
  spfFour,
  bestSellerOne,
  bestSellerTwo,
  bestSellerThree,
  bestSellerFour,
  newArrOne,
  newArrTwo,
  newArrThree,
  newArrFour,
  imprimante1,
  imprimante2,
  imprimante4,
  imprimante5,
  imprimante6,
  imprimante7,
  imprimante8,
  imprimante9,
  encre1,
  encre2,
  encre3,
  encre4,
  ruban1,
  ruban2,
  ruban3,
  ruban4,
  bac1,
  bac2,
  bac3,
  bac4,
  pdf1,
  IMPRIMANTE_PANTUM_CP2200DW,
  IMPRIMANTE_PANTUM_BM5100FDW,
  IMPRIMANTE_PANTUM_M6609N,
  IMPRIMANTE_PANTUM_P3300DN,
  IMPRIMANTE_PANTUM_P3300DW,
  IMPRIMANTE_PANTUM_P2509W,
  IMPRIMANTE_PANTUM_BP5100DN,
  IMPRIMANTE_PANTUM_M6559N,
  IMPRIMANTE_PANTUM_P2509,
  hp1,
  hp2,
  hp3,
  ricoh1,
  ricoh2,
  ricoh3,
  ricoh4,
  ricoh5,
  espson1,
  espson2,
} from "../assets/images/index";

import "./style.css";

// =================== NavBarList Start here ====================
export const navBarList = [
  {
    _id: 1001,
    title: "მთავარი",
    link: "/",
  },
  {
    _id: 1002,
    title: "პროდუქცია",
    link: "/shop",
  },
  {
    _id: 1003,
    title: "ჩვენს შესახებ",
    link: "/about",
  },
  {
    _id: 1004,
    title: "კონტაქტი",
    link: "contact",
  },
  
];
// =================== NavBarList End here ======================
// =================== Special Offer data Start here ============
export const SplOfferData = [
  {
    _id: "201",
    img: 'https://i.imgur.com/4nomS7B.jpg',
    productName: "ფანერა 3მმ",
    price: "31.00",
    color: "I/II ხარისხი",
    badge: false,
    brand: "Pantum",
    des: "1/2 ხარისხის ფანერა 3მმ სისქის",
    cat: "1525 x 1525mm",
    pdf: pdf1,
    ficheTech: [
      { label: "ხარისხი ", value: "1/2 ხარისხი" },
      { label: "ფორმატი", value: "1525 x 1525mm" },

      { label: "სისქე", value: "3 მმ" },
      { label: "მწარმოებელი ქვეყანა", value: "ბელორუსული" },
      
      {label: "ხის ჯიში", value: "არყის ხე",},
      { label: "ტენიანობა", value: "8–10%" },
    ],
  },
  {
    _id: "202",
    img: imprimante2,
    productName: "imprimante",
    price: "180.00",
    color: "Gray",
    badge: true,
    des: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis.",
    cat: "imprimante",
  },
  {
    _id: "203",
    img: imprimante4,
    productName: "imprimante",
    price: "25.00",
    color: "Mixed",
    badge: true,
    des: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis.",
    cat: "imprimante",
  },
  {
    _id: "204",
    img: imprimante5,
    productName: "imprimante",
    price: "220.00",
    color: "Black",
    badge: true,
    des: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis.",
    cat: "imprimante",
  },
  {
    _id: "205",
    img: imprimante6,
    productName: "imprimante",
    price: "25.00",
    color: "Mixed",
    badge: true,
    des: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis.",
    cat: "imprimante",
  },
  {
    _id: "206",
    img: imprimante7,
    productName: "imprimante",
    price: "220.00",
    color: "Black",
    badge: true,
    des: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis.",
    cat: "imprimante",
  },
  {
    _id: "207",
    img: imprimante8,
    productName: "imprimante",
    price: "25.00",
    color: "Mixed",
    badge: true,
    des: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis.",
    cat: "imprimante",
  },
  {
    _id: "208",
    img: imprimante9,
    productName: "imprimante",
    price: "220.00",
    color: "Black",
    badge: true,
    des: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis.",
    cat: "imprimante",
  },
  {
    _id: "209",
    img: encre1,
    productName: "encre",
    price: "25.00",
    color: "Mixed",
    badge: true,
    des: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis.",
    cat: "ancre",
  },
  {
    _id: "210",
    img: encre2,
    productName: "encre",
    price: "220.00",
    color: "Black",
    badge: true,
    des: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis.",
    cat: "ancre",
  },
  {
    _id: "211",
    img: encre3,
    productName: "encre",
    price: "25.00",
    color: "Mixed",
    badge: true,
    des: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis.",
    cat: "ancre",
  },
  {
    _id: "212",
    img: encre4,
    productName: "encre",
    price: "220.00",
    color: "Black",
    badge: true,
    des: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis.",
    cat: "ancre",
  },
  {
    _id: "213",
    img: ruban1,
    productName: "Ruban",
    price: "25.00",
    color: "Mixed",
    badge: true,
    des: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis.",
    cat: "Ruban",
  },

];
// =================== Special Offer data End here ==============

// =================== PaginationItems Start here ===============

export const paginationItems = [
  {
    _id: "201",
    img: 'https://i.imgur.com/uCk1O4o.jpg',
    productName: "ფანერა 3მმ",
    price: "31.00",
    color: "I/II ხარისხი",
    badge: false,
    brand: "Pantum",
    des: "1 ხარისხი პირველი კლასი გამორიცხავს მნიშვნელოვან დეფექტებს, მაგრამ დაუშვებს კვანძებისა და ხვრელების გარკვეულ რაოდენობას (არაუმეტეს 3 ცალი 1 მ2-ზე) და ხის სხვა მცირე დეფექტებს. ეს კლასი გამოიყენება ავეჯის ჩარჩოების დასამზადებლად, ინტერიერის გაფორმებისთვის და შესაფერისია ლამინირებისა და ლაქის საფარისთვის. 2 ხარისხი შეიძლება იყოს შერწყმული და ამოვარდნილი კვანძები (10 ცალი დიამეტრით არაუმეტეს 2.5 მმ), ასევე ჭიის ხვრელები (არაუმეტეს 6 ცალი 1 მ²-ზე). 25 მმ-ზე მეტი დიამეტრის ამოვარდნილი კვანძების ჩაკერება ; ასეთი 'ლუქების' რაოდენობა არ უნდა აღემატებოდეს 8 ცალს 1მ2-ზე.",
    cat: "1525 x 1525მმ",
    pdf: pdf1,
    ficheTech: [
      { label: "ხარისხი ", value: "1/2 ხარისხი" },
      { label: "ფორმატი", value: "1525 x 1525მმ" },

      { label: "სისქე", value: "3 მმ" },
      { label: "მწარმოებელი ქვეყანა", value: "ბელორუსული" },
      
      {label: "ხის ჯიში", value: "არყის ხე",},
      { label: "ტენიანობა", value: "8–10%" },
    ],
  },
  {
    _id: "202",
    img: 'https://i.imgur.com/uCk1O4o.jpg',
    productName: "ფანერა 4მმ",
    price: "33.00",
    color: "I/II ხარისხი",
    badge: false,
    brand: "Pantum",
    des: "1 ხარისხი პირველი კლასი გამორიცხავს მნიშვნელოვან დეფექტებს, მაგრამ დაუშვებს კვანძებისა და ხვრელების გარკვეულ რაოდენობას (არაუმეტეს 3 ცალი 1 მ2-ზე) და ხის სხვა მცირე დეფექტებს. ეს კლასი გამოიყენება ავეჯის ჩარჩოების დასამზადებლად, ინტერიერის გაფორმებისთვის და შესაფერისია ლამინირებისა და ლაქის საფარისთვის. 2 ხარისხი შეიძლება იყოს შერწყმული და ამოვარდნილი კვანძები (10 ცალი დიამეტრით არაუმეტეს 2.5 მმ), ასევე ჭიის ხვრელები (არაუმეტეს 6 ცალი 1 მ²-ზე). 25 მმ-ზე მეტი დიამეტრის ამოვარდნილი კვანძების ჩაკერება ; ასეთი 'ლუქების' რაოდენობა არ უნდა აღემატებოდეს 8 ცალს 1მ2-ზე.",
    cat: "1525 x 1525მმ",
    pdf: pdf1,
    ficheTech: [
      { label: "ხარისხი ", value: "1/2 ხარისხი" },
      { label: "ფორმატი", value: "1525 x 1525მმ" },

      { label: "სისქე", value: "4 მმ" },
      { label: "მწარმოებელი ქვეყანა", value: "ბელორუსული" },
      
      {label: "ხის ჯიში", value: "არყის ხე",},
      { label: "ტენიანობა", value: "8–10%" },
    ],
  },

  {
    _id: "203",
    img: 'https://i.imgur.com/uCk1O4o.jpg',
    productName: "ფანერა 6მმ",
    price: "43.00",
    color: "I/II ხარისხი",
    badge: false,
    brand: "Pantum",
    des: "1 ხარისხი პირველი კლასი გამორიცხავს მნიშვნელოვან დეფექტებს, მაგრამ დაუშვებს კვანძებისა და ხვრელების გარკვეულ რაოდენობას (არაუმეტეს 3 ცალი 1 მ2-ზე) და ხის სხვა მცირე დეფექტებს. ეს კლასი გამოიყენება ავეჯის ჩარჩოების დასამზადებლად, ინტერიერის გაფორმებისთვის და შესაფერისია ლამინირებისა და ლაქის საფარისთვის. 2 ხარისხი შეიძლება იყოს შერწყმული და ამოვარდნილი კვანძები (10 ცალი დიამეტრით არაუმეტეს 2.5 მმ), ასევე ჭიის ხვრელები (არაუმეტეს 6 ცალი 1 მ²-ზე). 25 მმ-ზე მეტი დიამეტრის ამოვარდნილი კვანძების ჩაკერება ; ასეთი 'ლუქების' რაოდენობა არ უნდა აღემატებოდეს 8 ცალს 1მ2-ზე.",
    cat: "1525 x 1525მმ",
    pdf: pdf1,
    ficheTech: [
      { label: "ხარისხი ", value: "1/2 ხარისხი" },
      { label: "ფორმატი", value: "1525 x 1525მმ" },

      { label: "სისქე", value: "6 მმ" },
      { label: "მწარმოებელი ქვეყანა", value: "ბელორუსული" },
      
      {label: "ხის ჯიში", value: "არყის ხე",},
      { label: "ტენიანობა", value: "8–10%" },
    ],
  },
  {
    _id: "205",
    img: 'https://i.imgur.com/uCk1O4o.jpg',
    productName: "ფანერა 8მმ",
    price: "53.00",
    color: "I/II ხარისხი",
    badge: false,
    brand: "Pantum",
    des: "1 ხარისხი პირველი კლასი გამორიცხავს მნიშვნელოვან დეფექტებს, მაგრამ დაუშვებს კვანძებისა და ხვრელების გარკვეულ რაოდენობას (არაუმეტეს 3 ცალი 1 მ2-ზე) და ხის სხვა მცირე დეფექტებს. ეს კლასი გამოიყენება ავეჯის ჩარჩოების დასამზადებლად, ინტერიერის გაფორმებისთვის და შესაფერისია ლამინირებისა და ლაქის საფარისთვის. 2 ხარისხი შეიძლება იყოს შერწყმული და ამოვარდნილი კვანძები (10 ცალი დიამეტრით არაუმეტეს 2.5 მმ), ასევე ჭიის ხვრელები (არაუმეტეს 6 ცალი 1 მ²-ზე). 25 მმ-ზე მეტი დიამეტრის ამოვარდნილი კვანძების ჩაკერება ; ასეთი 'ლუქების' რაოდენობა არ უნდა აღემატებოდეს 8 ცალს 1მ2-ზე.",
    cat: "1525 x 1525მმ",
    pdf: pdf1,
    ficheTech: [
      { label: "ხარისხი ", value: "1/2 ხარისხი" },
      { label: "ფორმატი", value: "1525 x 1525მმ" },

      { label: "სისქე", value: "8 მმ" },
      { label: "მწარმოებელი ქვეყანა", value: "ბელორუსული" },
      
      {label: "ხის ჯიში", value: "არყის ხე",},
      { label: "ტენიანობა", value: "8–10%" },
    ],
  },

  {
    _id: "206",
    img: 'https://i.imgur.com/uCk1O4o.jpg',
    productName: "ფანერა 10მმ",
    price: "60.00",
    color: "I/II ხარისხი",
    badge: false,
    brand: "Pantum",
    des: "1 ხარისხი პირველი კლასი გამორიცხავს მნიშვნელოვან დეფექტებს, მაგრამ დაუშვებს კვანძებისა და ხვრელების გარკვეულ რაოდენობას (არაუმეტეს 3 ცალი 1 მ2-ზე) და ხის სხვა მცირე დეფექტებს. ეს კლასი გამოიყენება ავეჯის ჩარჩოების დასამზადებლად, ინტერიერის გაფორმებისთვის და შესაფერისია ლამინირებისა და ლაქის საფარისთვის. 2 ხარისხი შეიძლება იყოს შერწყმული და ამოვარდნილი კვანძები (10 ცალი დიამეტრით არაუმეტეს 2.5 მმ), ასევე ჭიის ხვრელები (არაუმეტეს 6 ცალი 1 მ²-ზე). 25 მმ-ზე მეტი დიამეტრის ამოვარდნილი კვანძების ჩაკერება ; ასეთი 'ლუქების' რაოდენობა არ უნდა აღემატებოდეს 8 ცალს 1მ2-ზე.",
    cat: "1525 x 1525მმ",
    pdf: pdf1,
    ficheTech: [
      { label: "ხარისხი ", value: "1/2 ხარისხი" },
      { label: "ფორმატი", value: "1525 x 1525მმ" },

      { label: "სისქე", value: "10 მმ" },
      { label: "მწარმოებელი ქვეყანა", value: "ბელორუსული" },
      
      {label: "ხის ჯიში", value: "არყის ხე",},
      { label: "ტენიანობა", value: "8–10%" },
    ],
  },

  {
    _id: "207",
    img: 'https://i.imgur.com/uCk1O4o.jpg',
    productName: "ფანერა 12მმ",
    price: "74.00",
    color: "I/II ხარისხი",
    badge: false,
    brand: "Pantum",
    des: "1 ხარისხი პირველი კლასი გამორიცხავს მნიშვნელოვან დეფექტებს, მაგრამ დაუშვებს კვანძებისა და ხვრელების გარკვეულ რაოდენობას (არაუმეტეს 3 ცალი 1 მ2-ზე) და ხის სხვა მცირე დეფექტებს. ეს კლასი გამოიყენება ავეჯის ჩარჩოების დასამზადებლად, ინტერიერის გაფორმებისთვის და შესაფერისია ლამინირებისა და ლაქის საფარისთვის. 2 ხარისხი შეიძლება იყოს შერწყმული და ამოვარდნილი კვანძები (10 ცალი დიამეტრით არაუმეტეს 2.5 მმ), ასევე ჭიის ხვრელები (არაუმეტეს 6 ცალი 1 მ²-ზე). 25 მმ-ზე მეტი დიამეტრის ამოვარდნილი კვანძების ჩაკერება ; ასეთი 'ლუქების' რაოდენობა არ უნდა აღემატებოდეს 8 ცალს 1მ2-ზე.",
    cat: "1525 x 1525მმ",
    pdf: pdf1,
    ficheTech: [
      { label: "ხარისხი ", value: "1/2 ხარისხი" },
      { label: "ფორმატი", value: "1525 x 1525მმ" },

      { label: "სისქე", value: "12 მმ" },
      { label: "მწარმოებელი ქვეყანა", value: "ბელორუსული" },
      
      {label: "ხის ჯიში", value: "არყის ხე",},
      { label: "ტენიანობა", value: "8–10%" },
    ],
  },

  {
    _id: "208",
    img: 'https://i.imgur.com/uCk1O4o.jpg',
    productName: "ფანერა 15მმ",
    price: "88.00",
    color: "I/II ხარისხი",
    badge: false,
    brand: "Pantum",
    des: "1 ხარისხი პირველი კლასი გამორიცხავს მნიშვნელოვან დეფექტებს, მაგრამ დაუშვებს კვანძებისა და ხვრელების გარკვეულ რაოდენობას (არაუმეტეს 3 ცალი 1 მ2-ზე) და ხის სხვა მცირე დეფექტებს. ეს კლასი გამოიყენება ავეჯის ჩარჩოების დასამზადებლად, ინტერიერის გაფორმებისთვის და შესაფერისია ლამინირებისა და ლაქის საფარისთვის. 2 ხარისხი შეიძლება იყოს შერწყმული და ამოვარდნილი კვანძები (10 ცალი დიამეტრით არაუმეტეს 2.5 მმ), ასევე ჭიის ხვრელები (არაუმეტეს 6 ცალი 1 მ²-ზე). 25 მმ-ზე მეტი დიამეტრის ამოვარდნილი კვანძების ჩაკერება ; ასეთი 'ლუქების' რაოდენობა არ უნდა აღემატებოდეს 8 ცალს 1მ2-ზე.",
    cat: "1525 x 1525მმ",
    pdf: pdf1,
    ficheTech: [
      { label: "ხარისხი ", value: "1/2 ხარისხი" },
      { label: "ფორმატი", value: "1525 x 1525მმ" },

      { label: "სისქე", value: "15 მმ" },
      { label: "მწარმოებელი ქვეყანა", value: "ბელორუსული" },
      
      {label: "ხის ჯიში", value: "არყის ხე",},
      { label: "ტენიანობა", value: "8–10%" },
    ],
  },

  {
    _id: "209",
    img: 'https://i.imgur.com/uCk1O4o.jpg',
    productName: "ფანერა 18სმმ",
    price: "100.00",
    color: "I/II ხარისხი",
    badge: false,
    brand: "Pantum",
    des: "1 ხარისხი პირველი კლასი გამორიცხავს მნიშვნელოვან დეფექტებს, მაგრამ დაუშვებს კვანძებისა და ხვრელების გარკვეულ რაოდენობას (არაუმეტეს 3 ცალი 1 მ2-ზე) და ხის სხვა მცირე დეფექტებს. ეს კლასი გამოიყენება ავეჯის ჩარჩოების დასამზადებლად, ინტერიერის გაფორმებისთვის და შესაფერისია ლამინირებისა და ლაქის საფარისთვის. 2 ხარისხი შეიძლება იყოს შერწყმული და ამოვარდნილი კვანძები (10 ცალი დიამეტრით არაუმეტეს 2.5 მმ), ასევე ჭიის ხვრელები (არაუმეტეს 6 ცალი 1 მ²-ზე). 25 მმ-ზე მეტი დიამეტრის ამოვარდნილი კვანძების ჩაკერება ; ასეთი 'ლუქების' რაოდენობა არ უნდა აღემატებოდეს 8 ცალს 1მ2-ზე.",
    cat: "1525 x 1525მმ",
    pdf: pdf1,
    ficheTech: [
      { label: "ხარისხი ", value: "1/2 ხარისხი" },
      { label: "ფორმატი", value: "1525 x 1525მმ" },

      { label: "სისქე", value: "18 მმ" },
      { label: "მწარმოებელი ქვეყანა", value: "ბელორუსული" },
      
      {label: "ხის ჯიში", value: "არყის ხე",},
      { label: "ტენიანობა", value: "8–10%" },
    ],
  },

];
export const paginationItems2 = [
   
  {
    _id: "201",
    img: 'https://i.imgur.com/4m1t8rc.jpg',
    productName: "ფანერა 3მმ",
    price: "21.00",
    color: "II/III ხარისხი",
    badge: false,
    brand: "Pantum",
    des: "2 ხარისხი შეიძლება იყოს შერწყმული და ამოვარდნილი კვანძები (10 ცალი დიამეტრით არაუმეტეს 2.5 მმ), ასევე ჭიის ხვრელები (არაუმეტეს 6 ცალი 1 მ²-ზე). 25 მმ-ზე მეტი დიამეტრის ამოვარდნილი კვანძების ჩაკერება ; ასეთი 'ლუქების' რაოდენობა არ უნდა აღემატებოდეს 8 ცალს 1მ2-ზე. 3 სორტი მას აქვს შეუზღუდავი რაოდენობის შერწყმული კვანძები და ჩაკერებები, ასევე ბზარები, ხარვეზები, ჭიის ხვრელები და მცირე ჩამოვარდნილი კვანძები (10 ცალი 1 მ2-ზე). 3 სორტის ფანერა გამოიყენება შენობების უხეში მოსაპირკეთებლად, შესაფუთი კონტეინერების ან სტრუქტურების წარმოებაში, რომელთა ესთეტიკური კომპონენტი არ არის მნიშვნელოვანი.",
    cat: "1525 x 1525მმ",
    pdf: pdf1,
    ficheTech: [
      { label: "ხარისხი ", value: "2/3 ხარისხი" },
      { label: "ფორმატი", value: "1525 x 1525მმ" },

      { label: "სისქე", value: "3მმ" },
      { label: "მწარმოებელი ქვეყანა", value: "ბელორუსული" },
      
      {label: "ხის ჯიში", value: "არყის ხე",},
      { label: "ტენიანობა", value: "8–10%" },
    ],
  },
  {
    _id: "202",
    img: 'https://i.imgur.com/4m1t8rc.jpg',
    productName: "ფანერა 4მმ",
    price: "22.00",
    color: "II/III ხარისხი",
    badge: false,
    brand: "Pantum",
    des: "2 ხარისხი შეიძლება იყოს შერწყმული და ამოვარდნილი კვანძები (10 ცალი დიამეტრით არაუმეტეს 2.5 მმ), ასევე ჭიის ხვრელები (არაუმეტეს 6 ცალი 1 მ²-ზე). 25 მმ-ზე მეტი დიამეტრის ამოვარდნილი კვანძების ჩაკერება ; ასეთი 'ლუქების' რაოდენობა არ უნდა აღემატებოდეს 8 ცალს 1მ2-ზე. 3 სორტი მას აქვს შეუზღუდავი რაოდენობის შერწყმული კვანძები და ჩაკერებები, ასევე ბზარები, ხარვეზები, ჭიის ხვრელები და მცირე ჩამოვარდნილი კვანძები (10 ცალი 1 მ2-ზე). 3 სორტის ფანერა გამოიყენება შენობების უხეში მოსაპირკეთებლად, შესაფუთი კონტეინერების ან სტრუქტურების წარმოებაში, რომელთა ესთეტიკური კომპონენტი არ არის მნიშვნელოვანი.",
    cat: "1525 x 1525mm",
    pdf: pdf1,
    ficheTech: [
      { label: "ხარისხი ", value: "2/3 ხარისხი" },
      { label: "ფორმატი", value: "1525 x 1525მმ" },

      { label: "სისქე", value: "4მმ" },
      { label: "მწარმოებელი ქვეყანა", value: "ბელორუსული" },
      
      {label: "ხის ჯიში", value: "არყის ხე",},
      { label: "ტენიანობა", value: "8–10%" },
    ],
  },

  {
    _id: "203",
    img: 'https://i.imgur.com/4m1t8rc.jpg',
    productName: "ფანერა 6მმ",
    price: "28.00",
    color: "II/III ხარისხი",
    badge: false,
    brand: "Pantum",
    des: "2 ხარისხი შეიძლება იყოს შერწყმული და ამოვარდნილი კვანძები (10 ცალი დიამეტრით არაუმეტეს 2.5 მმ), ასევე ჭიის ხვრელები (არაუმეტეს 6 ცალი 1 მ²-ზე). 25 მმ-ზე მეტი დიამეტრის ამოვარდნილი კვანძების ჩაკერება ; ასეთი 'ლუქების' რაოდენობა არ უნდა აღემატებოდეს 8 ცალს 1მ2-ზე. 3 სორტი მას აქვს შეუზღუდავი რაოდენობის შერწყმული კვანძები და ჩაკერებები, ასევე ბზარები, ხარვეზები, ჭიის ხვრელები და მცირე ჩამოვარდნილი კვანძები (10 ცალი 1 მ2-ზე). 3 სორტის ფანერა გამოიყენება შენობების უხეში მოსაპირკეთებლად, შესაფუთი კონტეინერების ან სტრუქტურების წარმოებაში, რომელთა ესთეტიკური კომპონენტი არ არის მნიშვნელოვანი.",
    cat: "1525 x 1525მმ",
    pdf: pdf1,
    ficheTech: [
      { label: "ხარისხი ", value: "2/3 ხარისხი" },
      { label: "ფორმატი", value: "1525 x 1525მმ" },

      { label: "სისქე", value: "6მმ" },
      { label: "მწარმოებელი ქვეყანა", value: "ბელორუსული" },
      
      {label: "ხის ჯიში", value: "არყის ხე",},
      { label: "ტენიანობა", value: "8–10%" },
    ],
  },
  {
    _id: "205",
    img: 'https://i.imgur.com/4m1t8rc.jpg',
    productName: "ფანერა 8მმ",
    price: "34.00",
    color: "II/III ხარისხი",
    badge: false,
    brand: "Pantum",
    des: "2 ხარისხი შეიძლება იყოს შერწყმული და ამოვარდნილი კვანძები (10 ცალი დიამეტრით არაუმეტეს 2.5 მმ), ასევე ჭიის ხვრელები (არაუმეტეს 6 ცალი 1 მ²-ზე). 25 მმ-ზე მეტი დიამეტრის ამოვარდნილი კვანძების ჩაკერება ; ასეთი 'ლუქების' რაოდენობა არ უნდა აღემატებოდეს 8 ცალს 1მ2-ზე. 3 სორტი მას აქვს შეუზღუდავი რაოდენობის შერწყმული კვანძები და ჩაკერებები, ასევე ბზარები, ხარვეზები, ჭიის ხვრელები და მცირე ჩამოვარდნილი კვანძები (10 ცალი 1 მ2-ზე). 3 სორტის ფანერა გამოიყენება შენობების უხეში მოსაპირკეთებლად, შესაფუთი კონტეინერების ან სტრუქტურების წარმოებაში, რომელთა ესთეტიკური კომპონენტი არ არის მნიშვნელოვანი.",
    cat: "1525 x 1525მმ",
    pdf: pdf1,
    ficheTech: [
      { label: "ხარისხი ", value: "2/3 ხარისხი" },
      { label: "ფორმატი", value: "1525 x 1525მმ" },

      { label: "სისქე", value: "8მმ" },
      { label: "მწარმოებელი ქვეყანა", value: "ბელორუსული" },
      
      {label: "ხის ჯიში", value: "არყის ხე",},
      { label: "ტენიანობა", value: "8–10%" },
    ],
  },

  {
    _id: "206",
    img: 'https://i.imgur.com/4m1t8rc.jpg',
    productName: "ფანერა 10მმ",
    price: "41.00",
    color: "II/III ხარისხი",
    badge: false,
    brand: "Pantum",
    des: "2 ხარისხი შეიძლება იყოს შერწყმული და ამოვარდნილი კვანძები (10 ცალი დიამეტრით არაუმეტეს 2.5 მმ), ასევე ჭიის ხვრელები (არაუმეტეს 6 ცალი 1 მ²-ზე). 25 მმ-ზე მეტი დიამეტრის ამოვარდნილი კვანძების ჩაკერება ; ასეთი 'ლუქების' რაოდენობა არ უნდა აღემატებოდეს 8 ცალს 1მ2-ზე. 3 სორტი მას აქვს შეუზღუდავი რაოდენობის შერწყმული კვანძები და ჩაკერებები, ასევე ბზარები, ხარვეზები, ჭიის ხვრელები და მცირე ჩამოვარდნილი კვანძები (10 ცალი 1 მ2-ზე). 3 სორტის ფანერა გამოიყენება შენობების უხეში მოსაპირკეთებლად, შესაფუთი კონტეინერების ან სტრუქტურების წარმოებაში, რომელთა ესთეტიკური კომპონენტი არ არის მნიშვნელოვანი.",
    cat: "1525 x 1525მმ",
    pdf: pdf1,
    ficheTech: [
      { label: "ხარისხი ", value: "2/3 ხარისხი" },
      { label: "ფორმატი", value: "1525 x 1525მმ" },

      { label: "სისქე", value: "10მმ" },
      { label: "მწარმოებელი ქვეყანა", value: "ბელორუსული" },
      
      {label: "ხის ჯიში", value: "არყის ხე",},
      { label: "ტენიანობა", value: "8–10%" },
    ],
  },

  {
    _id: "207",
    img: 'https://i.imgur.com/4m1t8rc.jpg',
    productName: "ფანერა 12მმ",
    price: "51.00",
    color: "II/III ხარისხი",
    badge: false,
    brand: "Pantum",
    des: "2 ხარისხი შეიძლება იყოს შერწყმული და ამოვარდნილი კვანძები (10 ცალი დიამეტრით არაუმეტეს 2.5 მმ), ასევე ჭიის ხვრელები (არაუმეტეს 6 ცალი 1 მ²-ზე). 25 მმ-ზე მეტი დიამეტრის ამოვარდნილი კვანძების ჩაკერება ; ასეთი 'ლუქების' რაოდენობა არ უნდა აღემატებოდეს 8 ცალს 1მ2-ზე. 3 სორტი მას აქვს შეუზღუდავი რაოდენობის შერწყმული კვანძები და ჩაკერებები, ასევე ბზარები, ხარვეზები, ჭიის ხვრელები და მცირე ჩამოვარდნილი კვანძები (10 ცალი 1 მ2-ზე). 3 სორტის ფანერა გამოიყენება შენობების უხეში მოსაპირკეთებლად, შესაფუთი კონტეინერების ან სტრუქტურების წარმოებაში, რომელთა ესთეტიკური კომპონენტი არ არის მნიშვნელოვანი.",
    cat: "1525 x 1525მმ",
    pdf: pdf1,
    ficheTech: [
      { label: "ხარისხი ", value: "2/3 ხარისხი" },
      { label: "ფორმატი", value: "1525 x 1525მმ" },

      { label: "სისქე", value: "12მმ" },
      { label: "მწარმოებელი ქვეყანა", value: "ბელორუსული" },
      
      {label: "ხის ჯიში", value: "არყის ხე",},
      { label: "ტენიანობა", value: "8–10%" },
    ],
  },

  {
    _id: "208",
    img: 'https://i.imgur.com/4m1t8rc.jpg',
    productName: "ფანერა 15მმ",
    price: "60.00",
    color: "II/III ხარისხი",
    badge: false,
    brand: "Pantum",
    des: "2 ხარისხი შეიძლება იყოს შერწყმული და ამოვარდნილი კვანძები (10 ცალი დიამეტრით არაუმეტეს 2.5 მმ), ასევე ჭიის ხვრელები (არაუმეტეს 6 ცალი 1 მ²-ზე). 25 მმ-ზე მეტი დიამეტრის ამოვარდნილი კვანძების ჩაკერება ; ასეთი 'ლუქების' რაოდენობა არ უნდა აღემატებოდეს 8 ცალს 1მ2-ზე. 3 სორტი მას აქვს შეუზღუდავი რაოდენობის შერწყმული კვანძები და ჩაკერებები, ასევე ბზარები, ხარვეზები, ჭიის ხვრელები და მცირე ჩამოვარდნილი კვანძები (10 ცალი 1 მ2-ზე). 3 სორტის ფანერა გამოიყენება შენობების უხეში მოსაპირკეთებლად, შესაფუთი კონტეინერების ან სტრუქტურების წარმოებაში, რომელთა ესთეტიკური კომპონენტი არ არის მნიშვნელოვანი.",
    cat: "1525 x 1525მმ",
    pdf: pdf1,
    ficheTech: [
      { label: "ხარისხი ", value: "2/3 ხარისხი" },
      { label: "ფორმატი", value: "1525 x 1525მმ" },

      { label: "სისქე", value: "15მმ" },
      { label: "მწარმოებელი ქვეყანა", value: "ბელორუსული" },
      
      {label: "ხის ჯიში", value: "არყის ხე",},
      { label: "ტენიანობა", value: "8–10%" },
    ],
  },

  {
    _id: "209",
    img: 'https://i.imgur.com/4m1t8rc.jpg',
    productName: "ფანერა 18მმ",
    price: "72.00",
    color: "II/III ხარისხი",
    badge: false,
    brand: "Pantum",
    des: "2 ხარისხი შეიძლება იყოს შერწყმული და ამოვარდნილი კვანძები (10 ცალი დიამეტრით არაუმეტეს 2.5 მმ), ასევე ჭიის ხვრელები (არაუმეტეს 6 ცალი 1 მ²-ზე). 25 მმ-ზე მეტი დიამეტრის ამოვარდნილი კვანძების ჩაკერება ; ასეთი 'ლუქების' რაოდენობა არ უნდა აღემატებოდეს 8 ცალს 1მ2-ზე. 3 სორტი მას აქვს შეუზღუდავი რაოდენობის შერწყმული კვანძები და ჩაკერებები, ასევე ბზარები, ხარვეზები, ჭიის ხვრელები და მცირე ჩამოვარდნილი კვანძები (10 ცალი 1 მ2-ზე). 3 სორტის ფანერა გამოიყენება შენობების უხეში მოსაპირკეთებლად, შესაფუთი კონტეინერების ან სტრუქტურების წარმოებაში, რომელთა ესთეტიკური კომპონენტი არ არის მნიშვნელოვანი.",
    cat: "1525 x 1525მმ",
    pdf: pdf1,
    ficheTech: [
      { label: "ხარისხი ", value: "2/3 ხარისხი" },
      { label: "ფორმატი", value: "1525 x 1525მმ" },

      { label: "სისქე", value: "18მმ" },
      { label: "მწარმოებელი ქვეყანა", value: "ბელორუსული" },
      
      {label: "ხის ჯიში", value: "არყის ხე",},
      { label: "ტენიანობა", value: "8–10%" },
    ],
  },
  {
    _id: "210",
    img: 'https://i.imgur.com/4m1t8rc.jpg',
    productName: "ფანერა 24მმ",
    price: "100.00",
    color: "II/III ხარისხი",
    badge: false,
    brand: "Pantum",
    des: "2 ხარისხი შეიძლება იყოს შერწყმული და ამოვარდნილი კვანძები (10 ცალი დიამეტრით არაუმეტეს 2.5 მმ), ასევე ჭიის ხვრელები (არაუმეტეს 6 ცალი 1 მ²-ზე). 25 მმ-ზე მეტი დიამეტრის ამოვარდნილი კვანძების ჩაკერება ; ასეთი 'ლუქების' რაოდენობა არ უნდა აღემატებოდეს 8 ცალს 1მ2-ზე. 3 სორტი მას აქვს შეუზღუდავი რაოდენობის შერწყმული კვანძები და ჩაკერებები, ასევე ბზარები, ხარვეზები, ჭიის ხვრელები და მცირე ჩამოვარდნილი კვანძები (10 ცალი 1 მ2-ზე). 3 სორტის ფანერა გამოიყენება შენობების უხეში მოსაპირკეთებლად, შესაფუთი კონტეინერების ან სტრუქტურების წარმოებაში, რომელთა ესთეტიკური კომპონენტი არ არის მნიშვნელოვანი.",
    cat: "1525 x 1525მმ",
    pdf: pdf1,
    ficheTech: [
      { label: "ხარისხი ", value: "2/3 ხარისხი" },
      { label: "ფორმატი", value: "1525 x 1525მმ" },

      { label: "სისქე", value: "24მმ" },
      { label: "მწარმოებელი ქვეყანა", value: "ბელორუსული" },
      
      {label: "ხის ჯიში", value: "არყის ხე",},
      { label: "ტენიანობა", value: "8–10%" },
    ],
  },
];
export const paginationItems3 = [
  {
    _id: "201",
    img: 'https://i.imgur.com/Ifw13jV.jpg',
    productName: "ფანერა 4მმ",
    price: "18.00",
    color: "III/IV ხარისხი",
    badge: false,
    brand: "Pantum",
    des: "3 სორტი მას აქვს შეუზღუდავი რაოდენობის შერწყმული კვანძები და ჩაკერებები, ასევე ბზარები, ხარვეზები, ჭიის ხვრელები და მცირე ჩამოვარდნილი კვანძები (10 ცალი 1 მ2-ზე). 3 სორტის ფანერა გამოიყენება შენობების უხეში მოსაპირკეთებლად, შესაფუთი კონტეინერების ან სტრუქტურების წარმოებაში, რომელთა ესთეტიკური კომპონენტი არ არის მნიშვნელოვანი. 4 ხარისხი ზემოთ ჩამოთვლილი ნებისმიერი სახის დეფექტი მისაღებია ამ მარკირებისთვის. მათი რაოდენობა შეზღუდული არ არის, მაგრამ იმ პირობით, რომ ხვრელების დიამეტრი არ აღემატება 40 მმ-ს.",
    cat: "1525 x 1525მმ",
    pdf: pdf1,
    ficheTech: [
      { label: "ხარისხი ", value: "3/4 ხარისხი" },
      { label: "ფორმატი", value: "1525 x 1525მმ" },

      { label: "სისქე", value: "4მმ" },
      { label: "მწარმოებელი ქვეყანა", value: "ბელორუსული" },
      
      {label: "ხის ჯიში", value: "არყის ხე",},
      { label: "ტენიანობა", value: "8–10%" },
    ],
  },
  {
    _id: "202",
    img: 'https://i.imgur.com/Ifw13jV.jpg',
    productName: "ფანერა 6მმ",
    price: "25.00",
    color: "III/IV ხარისხი",
    badge: false,
    brand: "Pantum",
    des: "3 სორტი მას აქვს შეუზღუდავი რაოდენობის შერწყმული კვანძები და ჩაკერებები, ასევე ბზარები, ხარვეზები, ჭიის ხვრელები და მცირე ჩამოვარდნილი კვანძები (10 ცალი 1 მ2-ზე). 3 სორტის ფანერა გამოიყენება შენობების უხეში მოსაპირკეთებლად, შესაფუთი კონტეინერების ან სტრუქტურების წარმოებაში, რომელთა ესთეტიკური კომპონენტი არ არის მნიშვნელოვანი. 4 ხარისხი ზემოთ ჩამოთვლილი ნებისმიერი სახის დეფექტი მისაღებია ამ მარკირებისთვის. მათი რაოდენობა შეზღუდული არ არის, მაგრამ იმ პირობით, რომ ხვრელების დიამეტრი არ აღემატება 40 მმ-ს.",
    cat: "1525 x 1525მმ",
    pdf: pdf1,
    ficheTech: [
      { label: "ხარისხი ", value: "3/4 ხარისხი" },
      { label: "ფორმატი", value: "1525 x 1525მმ" },

      { label: "სისქე", value: "6მმ" },
      { label: "მწარმოებელი ქვეყანა", value: "ბელორუსული" },
      
      {label: "ხის ჯიში", value: "არყის ხე",},
      { label: "ტენიანობა", value: "8–10%" },
    ],
  },

  {
    _id: "203",
    img: 'https://i.imgur.com/Ifw13jV.jpg',
    productName: "ფანერა 8მმ",
    price: "28.00",
    color: "III/IV ხარისხი",
    badge: false,
    brand: "Pantum",
    des: "3 სორტი მას აქვს შეუზღუდავი რაოდენობის შერწყმული კვანძები და ჩაკერებები, ასევე ბზარები, ხარვეზები, ჭიის ხვრელები და მცირე ჩამოვარდნილი კვანძები (10 ცალი 1 მ2-ზე). 3 სორტის ფანერა გამოიყენება შენობების უხეში მოსაპირკეთებლად, შესაფუთი კონტეინერების ან სტრუქტურების წარმოებაში, რომელთა ესთეტიკური კომპონენტი არ არის მნიშვნელოვანი. 4 ხარისხი ზემოთ ჩამოთვლილი ნებისმიერი სახის დეფექტი მისაღებია ამ მარკირებისთვის. მათი რაოდენობა შეზღუდული არ არის, მაგრამ იმ პირობით, რომ ხვრელების დიამეტრი არ აღემატება 40 მმ-ს.",
    cat: "1525 x 1525მმ",
    pdf: pdf1,
    ficheTech: [
      { label: "ხარისხი ", value: "3/4 ხარისხი" },
      { label: "ფორმატი", value: "1525 x 1525მმ" },

      { label: "სისქე", value: "6 მმ" },
      { label: "მწარმოებელი ქვეყანა", value: "ბელორუსული" },
      
      {label: "ხის ჯიში", value: "არყის ხე",},
      { label: "ტენიანობა", value: "8–10%" },
    ],
  },
  {
    _id: "205",
    img: 'https://i.imgur.com/Ifw13jV.jpg',
    productName: "ფანერა 10მმ",
    price: "34.00",
    color: "III/IV ხარისხი",
    badge: false,
    brand: "Pantum",
    des: "3 სორტი მას აქვს შეუზღუდავი რაოდენობის შერწყმული კვანძები და ჩაკერებები, ასევე ბზარები, ხარვეზები, ჭიის ხვრელები და მცირე ჩამოვარდნილი კვანძები (10 ცალი 1 მ2-ზე). 3 სორტის ფანერა გამოიყენება შენობების უხეში მოსაპირკეთებლად, შესაფუთი კონტეინერების ან სტრუქტურების წარმოებაში, რომელთა ესთეტიკური კომპონენტი არ არის მნიშვნელოვანი. 4 ხარისხი ზემოთ ჩამოთვლილი ნებისმიერი სახის დეფექტი მისაღებია ამ მარკირებისთვის. მათი რაოდენობა შეზღუდული არ არის, მაგრამ იმ პირობით, რომ ხვრელების დიამეტრი არ აღემატება 40 მმ-ს.",
    cat: "1525 x 1525მმ",
    pdf: pdf1,
    ficheTech: [
      { label: "ხარისხი ", value: "3/4 ხარისხი" },
      { label: "ფორმატი", value: "1525 x 1525მმ" },

      { label: "სისქე", value: "8 მმ" },
      { label: "მწარმოებელი ქვეყანა", value: "ბელორუსული" },
      
      {label: "ხის ჯიში", value: "არყის ხე",},
      { label: "ტენიანობა", value: "8–10%" },
    ],
  },

  {
    _id: "206",
    img: 'https://i.imgur.com/Ifw13jV.jpg',
    productName: "ფანერა 12მმ",
    price: "41.00",
    color: "III/IV ხარისხი",
    badge: false,
    brand: "Pantum",
    des: "3 სორტი მას აქვს შეუზღუდავი რაოდენობის შერწყმული კვანძები და ჩაკერებები, ასევე ბზარები, ხარვეზები, ჭიის ხვრელები და მცირე ჩამოვარდნილი კვანძები (10 ცალი 1 მ2-ზე). 3 სორტის ფანერა გამოიყენება შენობების უხეში მოსაპირკეთებლად, შესაფუთი კონტეინერების ან სტრუქტურების წარმოებაში, რომელთა ესთეტიკური კომპონენტი არ არის მნიშვნელოვანი. 4 ხარისხი ზემოთ ჩამოთვლილი ნებისმიერი სახის დეფექტი მისაღებია ამ მარკირებისთვის. მათი რაოდენობა შეზღუდული არ არის, მაგრამ იმ პირობით, რომ ხვრელების დიამეტრი არ აღემატება 40 მმ-ს.",
    cat: "1525 x 1525მმ",
    pdf: pdf1,
    ficheTech: [
      { label: "ხარისხი ", value: "3/4 ხარისხი" },
      { label: "ფორმატი", value: "1525 x 1525მმ" },

      { label: "სისქე", value: "12მმ" },
      { label: "მწარმოებელი ქვეყანა", value: "ბელორუსული" },
      
      {label: "ხის ჯიში", value: "არყის ხე",},
      { label: "ტენიანობა", value: "8–10%" },
    ],
  },

  {
    _id: "207",
    img: 'https://i.imgur.com/Ifw13jV.jpg',
    productName: "ფანერა 15მმ",
    price: "50.00",
    color: "III/IV ხარისხი",
    badge: false,
    brand: "Pantum",
    des: "3 სორტი მას აქვს შეუზღუდავი რაოდენობის შერწყმული კვანძები და ჩაკერებები, ასევე ბზარები, ხარვეზები, ჭიის ხვრელები და მცირე ჩამოვარდნილი კვანძები (10 ცალი 1 მ2-ზე). 3 სორტის ფანერა გამოიყენება შენობების უხეში მოსაპირკეთებლად, შესაფუთი კონტეინერების ან სტრუქტურების წარმოებაში, რომელთა ესთეტიკური კომპონენტი არ არის მნიშვნელოვანი. 4 ხარისხი ზემოთ ჩამოთვლილი ნებისმიერი სახის დეფექტი მისაღებია ამ მარკირებისთვის. მათი რაოდენობა შეზღუდული არ არის, მაგრამ იმ პირობით, რომ ხვრელების დიამეტრი არ აღემატება 40 მმ-ს.",
    cat: "1525 x 1525მმ",
    pdf: pdf1,
    ficheTech: [
      { label: "ხარისხი ", value: "3/4 ხარისხი" },
      { label: "ფორმატი", value: "1525 x 1525მმ" },

      { label: "სისქე", value: "15მმ" },
      { label: "მწარმოებელი ქვეყანა", value: "ბელორუსული" },
      
      {label: "ხის ჯიში", value: "არყის ხე",},
      { label: "ტენიანობა", value: "8–10%" },
    ],
  },

  {
    _id: "208",
    img: 'https://i.imgur.com/Ifw13jV.jpg',
    productName: "ფანერა 18მმ",
    price: "60.00",
    color: "III/IV ხარისხი",
    badge: false,
    brand: "Pantum",
    des: "3 სორტი მას აქვს შეუზღუდავი რაოდენობის შერწყმული კვანძები და ჩაკერებები, ასევე ბზარები, ხარვეზები, ჭიის ხვრელები და მცირე ჩამოვარდნილი კვანძები (10 ცალი 1 მ2-ზე). 3 სორტის ფანერა გამოიყენება შენობების უხეში მოსაპირკეთებლად, შესაფუთი კონტეინერების ან სტრუქტურების წარმოებაში, რომელთა ესთეტიკური კომპონენტი არ არის მნიშვნელოვანი. 4 ხარისხი ზემოთ ჩამოთვლილი ნებისმიერი სახის დეფექტი მისაღებია ამ მარკირებისთვის. მათი რაოდენობა შეზღუდული არ არის, მაგრამ იმ პირობით, რომ ხვრელების დიამეტრი არ აღემატება 40 მმ-ს.",
    cat: "1525 x 1525მმ",
    pdf: pdf1,
    ficheTech: [
      { label: "ხარისხი ", value: "3/4 ხარისხი" },
      { label: "ფორმატი", value: "1525 x 1525მმ" },

      { label: "სისქე", value: "18მმ" },
      { label: "მწარმოებელი ქვეყანა", value: "ბელორუსული" },
      
      {label: "ხის ჯიში", value: "არყის ხე",},
      { label: "ტენიანობა", value: "8–10%" },
    ],
  },

  {
    _id: "209",
    img: 'https://i.imgur.com/Ifw13jV.jpg',
    productName: "ფანერა 24მმ",
    price: "85.00",
    color: "III/IV ხარისხი",
    badge: false,
    brand: "Pantum",
    des: "3 სორტი მას აქვს შეუზღუდავი რაოდენობის შერწყმული კვანძები და ჩაკერებები, ასევე ბზარები, ხარვეზები, ჭიის ხვრელები და მცირე ჩამოვარდნილი კვანძები (10 ცალი 1 მ2-ზე). 3 სორტის ფანერა გამოიყენება შენობების უხეში მოსაპირკეთებლად, შესაფუთი კონტეინერების ან სტრუქტურების წარმოებაში, რომელთა ესთეტიკური კომპონენტი არ არის მნიშვნელოვანი. 4 ხარისხი ზემოთ ჩამოთვლილი ნებისმიერი სახის დეფექტი მისაღებია ამ მარკირებისთვის. მათი რაოდენობა შეზღუდული არ არის, მაგრამ იმ პირობით, რომ ხვრელების დიამეტრი არ აღემატება 40 მმ-ს.",
    cat: "1525 x 1525მმ",
    pdf: pdf1,
    ficheTech: [
      { label: "ხარისხი ", value: "3/4 ხარისხი" },
      { label: "ფორმატი", value: "1525 x 1525მმ" },

      { label: "სისქე", value: "30მმ" },
      { label: "მწარმოებელი ქვეყანა", value: "ბელორუსული" },
      
      {label: "ხის ჯიში", value: "არყის ხე",},
      { label: "ტენიანობა", value: "8–10%" },
    ],
  },
 
];
export const paginationItems4 = [
  {
    _id: "201",
    img: 'https://i.imgur.com/7And5U0.jpg',
    productName: "ადელი",
    price: "25.00",
    color: "33 კლასი",
    badge: false,
    brand: "Pantum",
    des: "კომპანია “EUROWOOD”-ი გთავაზობთ ბელარუსის წარმოების ლამინირებული იატაკს. იგი აერთიანებს სილამაზეს, გამძლეობას, ხარისხს და შედარებით დაბალ ფასს.",
    cat: "190 x 1380 x 8მმ",
    pdf: pdf1,
    ficheTech: [
      { label: "კლასი", value: "33" },
      { label: "ფორმატი", value: "190 x 1380 x 8მმ" },

      { label: "ფასკა", value: "უფასკო" },
      { label: "მწარმოებელი ქვეყანა", value: "ბელორუსული" },
      
      {label: "ცვეთის კლასი", value: "AC5",},
    ],
  },
  {
    _id: "202",
    img: 'https://i.imgur.com/mvl3jK3.jpg',
    productName: "ანრი",
    price: "25.00",
    color: "33 კლასი",
    badge: false,
    brand: "Pantum",
    des: "კომპანია “EUROWOOD”-ი გთავაზობთ ბელარუსის წარმოების ლამინირებული იატაკს. იგი აერთიანებს სილამაზეს, გამძლეობას, ხარისხს და შედარებით დაბალ ფასს.",
    cat: "190 x 1380 x 8მმ",
    pdf: pdf1,
    ficheTech: [
      { label: "კლასი", value: "33" },
      { label: "ფორმატი", value: "190 x 1380 x 8მმ" },

      { label: "ფასკა", value: "უფასკო" },
      { label: "მწარმოებელი ქვეყანა", value: "ბელორუსული" },
      
      {label: "ცვეთის კლასი", value: "AC5",},
    ],
  },

  {
    _id: "203",
    img: 'https://i.imgur.com/d9HgTkR.jpg',
    productName: "ჩარლი",
    price: "25.00",
    color: "33 კლასი",
    badge: false,
    brand: "Pantum",
    des: "კომპანია “EUROWOOD”-ი გთავაზობთ ბელარუსის წარმოების ლამინირებული იატაკს. იგი აერთიანებს სილამაზეს, გამძლეობას, ხარისხს და შედარებით დაბალ ფასს.",
    cat: "190 x 1380 x 8მმ",
    pdf: pdf1,
    ficheTech: [
      { label: "კლასი", value: "33" },
      { label: "ფორმატი", value: "190 x 1380 x 8მმ" },

      { label: "ფასკა", value: "უფასკო" },
      { label: "მწარმოებელი ქვეყანა", value: "ბელორუსული" },
      
      {label: "ცვეთის კლასი", value: "AC5",},
    ],
  },
  {
    _id: "205",
    img: 'https://i.imgur.com/okM6y3a.jpg',
    productName: "ეთერი",
    price: "25.00",
    color: "33 კლასი",
    badge: false,
    brand: "Pantum",
    des: "კომპანია “EUROWOOD”-ი გთავაზობთ ბელარუსის წარმოების ლამინირებული იატაკს. იგი აერთიანებს სილამაზეს, გამძლეობას, ხარისხს და შედარებით დაბალ ფასს.",
    cat: "190 x 1380 x 8მმ",
    pdf: pdf1,
    ficheTech: [
      { label: "კლასი", value: "33" },
      { label: "ფორმატი", value: "190 x 1380 x 8მმ" },

      { label: "ფასკა", value: "უფასკო" },
      { label: "მწარმოებელი ქვეყანა", value: "ბელორუსული" },
      
      {label: "ცვეთის კლასი", value: "AC5",},
    ],
  },

  {
    _id: "206",
    img: 'https://i.imgur.com/waxxJHo.jpg',
    productName: "ეტრანი",
    price: "25.00",
    color: "33 კლასი",
    badge: false,
    brand: "Pantum",
    des: "კომპანია “EUROWOOD”-ი გთავაზობთ ბელარუსის წარმოების ლამინირებული იატაკს. იგი აერთიანებს სილამაზეს, გამძლეობას, ხარისხს და შედარებით დაბალ ფასს.",
    cat: "190 x 1380 x 8მმ",
    pdf: pdf1,
    ficheTech: [
      { label: "კლასი", value: "33" },
      { label: "ფორმატი", value: "190 x 1380 x 8მმ" },

      { label: "ფასკა", value: "უფასკო" },
      { label: "მწარმოებელი ქვეყანა", value: "ბელორუსული" },
      
      {label: "ცვეთის კლასი", value: "AC5",},
    ],
  },

  {
    _id: "207",
    img: 'https://i.imgur.com/NBkqsyI.jpg',
    productName: "ფრონტერი",
    price: "25.00",
    color: "33 კლასი",
    badge: false,
    brand: "Pantum",
    des: "კომპანია “EUROWOOD”-ი გთავაზობთ ბელარუსის წარმოების ლამინირებული იატაკს. იგი აერთიანებს სილამაზეს, გამძლეობას, ხარისხს და შედარებით დაბალ ფასს.",
    cat: "190 x 1380 x 8მმ",
    pdf: pdf1,
    ficheTech: [
      { label: "კლასი", value: "33" },
      { label: "ფორმატი", value: "190 x 1380 x 8მმ" },

      { label: "ფასკა", value: "უფასკო" },
      { label: "მწარმოებელი ქვეყანა", value: "ბელორუსული" },
      
      {label: "ცვეთის კლასი", value: "AC5",},
    ],
  },

  {
    _id: "208",
    img: 'https://i.imgur.com/K940uth.jpg',
    productName: "კლასიკური",
    price: "25.00",
    color: "33 კლასი",
    badge: false,
    brand: "Pantum",
    des: "კომპანია “EUROWOOD”-ი გთავაზობთ ბელარუსის წარმოების ლამინირებული იატაკს. იგი აერთიანებს სილამაზეს, გამძლეობას, ხარისხს და შედარებით დაბალ ფასს.",
    cat: "190 x 1380 x 8მმ",
    pdf: pdf1,
    ficheTech: [
      { label: "კლასი", value: "33" },
      { label: "ფორმატი", value: "190 x 1380 x 8მმ" },

      { label: "ფასკა", value: "უფასკო" },
      { label: "მწარმოებელი ქვეყანა", value: "ბელორუსული" },
      
      {label: "ცვეთის კლასი", value: "AC5",},
    ],
  },

  {
    _id: "209",
    img: 'https://i.imgur.com/glle3bF.jpg',
    productName: "ლაფიტი",
    price: "25.00",
    color: "33 კლასი",
    badge: false,
    brand: "Pantum",
    des: "კომპანია “EUROWOOD”-ი გთავაზობთ ბელარუსის წარმოების ლამინირებული იატაკს. იგი აერთიანებს სილამაზეს, გამძლეობას, ხარისხს და შედარებით დაბალ ფასს.",
    cat: "190 x 1380 x 8მმ",
    pdf: pdf1,
    ficheTech: [
      { label: "კლასი", value: "33" },
      { label: "ფორმატი", value: "190 x 1380 x 8მმ" },

      { label: "ფასკა", value: "უფასკო" },
      { label: "მწარმოებელი ქვეყანა", value: "ბელორუსული" },
      
      {label: "ცვეთის კლასი", value: "AC5",},
    ],
  },
  
];
export const paginationItems5 = [
  {
    _id: "201",
    img: 'https://i.imgur.com/wOZj09m.jpg',
    img2: 'https://i.imgur.com/Tyj1hzQ.png',
    img3: 'https://i.imgur.com/WusaQAj.png',
    productName: "ბასტიონი",
    price: "49.00",
    color: "400 x 400 x 8მმ",
    badge: true,
    brand: "Pantum",
    des: "ოდესმე გისერნებიათ ვენის ქუჩებში? აღფრთოვანებული ხართ პრაღის ისტორიული კუთხეებით? სწორედ აქ აღმოაჩინეს Bastion კერამიკული გრანიტის ახალი კოლექციის პროტოტიპი. უძველესი ტროტუარის იმიტაციად შექმნილი Bastion კოლექცია განკუთვნილია როგორც ინტერიერის, ასევე ექსტერიერის დეკორაციისთვის.",
    cat: "400 x 400 x 8მმ",
    pdf: pdf1,
    ficheTech: [
      { label: "ხარისხი ", value: "1 სორტი" },
      { label: "წყალშთანთქვა", value: "0.5%" },

      { label: "ყინვაგამძლეობა", value: "100 ციკლი" },
      { label: "გამძლეობის კლასი", value: "5" },
      
      {label: "ფორმატი", value: "400 x 400 x 8მმ",},
      { label: "კოლოფში", value: "11 ცალი, 1.62 კვ.მ" },
      { label: "კოლოფის წონა", value: "30.80 კგ" },

    ],
  },
  {
    _id: "202",
    img: 'https://i.imgur.com/iLioxQS.png',
    img2: 'https://i.imgur.com/FrDRAPn.png',
    img3: 'https://i.imgur.com/1xQA7aC.png',
    productName: "კვარციტი 3",
    price: "49.00",
    color: "600 x 300 x 9მმ ",
    badge: true,
    brand: "Pantum",
    des: "კვარციტის კოლექცია შესანიშნავი საფუძველი იქნება თანამედროვე ინტერიერისთვის. ბუნებრივი ქვის ცოცხალი და ექსპრესიული ტექსტურა ხელს შეუწყობს ნებისმიერი ინტერიერის იდეას - მკაცრი კლასიკიდან ულტრათანამედროვე გადაწყვეტილებებამდე. ამასთან, ფილების ფონზე არც ერთი ავეჯი არ დაიკარგება.",
    cat: "600 x 300 x 9მმ",
    pdf: pdf1,
    ficheTech: [
      { label: "ხარისხი ", value: "1 სორტი" },
      { label: "წყალშთანთქვა", value: "0.5%" },

      { label: "ყინვაგამძლეობა", value: "100 ციკლი" },
      { label: "გამძლეობის კლასი", value: "5" },
      
      {label: "ფორმატი", value: "600x300x9მმ",},
      { label: "კოლოფში", value: "9 ცალი, 1.62 კვ.მ" },
      { label: "კოლოფის წონა", value: "30.80 კგ" },
    ],
  },
  {
    _id: "203",
    img: 'https://i.imgur.com/RUbM1A4.png',
    img2: 'https://i.imgur.com/FrDRAPn.png',
    img3: 'https://i.imgur.com/1xQA7aC.png',
    productName: "კვარციტი 7",
    price: "48.00",
    color: "600 x 300მმ",
    badge: true,
    brand: "Pantum",
    des: "კვარციტის კოლექცია შესანიშნავი საფუძველი იქნება თანამედროვე ინტერიერისთვის. ბუნებრივი ქვის ცოცხალი და ექსპრესიული ტექსტურა ხელს შეუწყობს ნებისმიერი ინტერიერის იდეას - მკაცრი კლასიკიდან ულტრათანამედროვე გადაწყვეტილებებამდე. ამასთან, ფილების ფონზე არც ერთი ავეჯი არ დაიკარგება.",
    cat: "600 x 300მმ",
    pdf: pdf1,
    ficheTech: [
      { label: "ხარისხი ", value: "1 სორტი" },
      { label: "წყალშთანთქვა", value: "0.5%" },

      { label: "ყინვაგამძლეობა", value: "100 ციკლი" },
      { label: "გამძლეობის კლასი", value: "5" },
      
      {label: "ფორმატი", value: "600x300x9მმ",},
      { label: "კოლოფში", value: "9 ცალი, 1.62 კვ.მ" },
      { label: "კოლოფის წონა", value: "30.80 კგ" },
    ],
  },
];
export const paginationItems6 = [
  {
    _id: "198",
    img: 'https://i.imgur.com/aJ3YRUR.png',
    img2: 'https://i.imgur.com/ID1izYv.png',
    img3: 'https://i.imgur.com/YPD0CBP.png',
    productName: "ამსტერდამი 7 აგური",
    price: "38.00",
    color: "245х65x7მმ",
    badge: true,
     brand: "Pantum",
    des: "კლინკერის ფილა - ექსტერიერისა და ინტერიერის გაფორმების ნამდვილი ტრენდია.მისი პოპულარობა განპირობებულია ტექსტურის და ფერის მრავალფეროვნებით, ასევე პროდუქტის მაღალი სიმტკიცით და გამძლეობით.",
    cat: "245х65x7მმ",
    pdf: pdf1,
    ficheTech: [
      { label: "ყინვაგამძლეობა", value: "100 სრული ციკლი" },
      { label: "წყალშთანთქვის მაჩვენებელი", value: "0.79–0.85%" },

      { label: "მოცურების მაჩვენებელი", value: "R10 ( 18.30 )" },
      { label: "ცვეთამედეგობა", value: "170.9" },
      
      {label: "მოღუნვის სიმტკიცე", value: "30 N/mm"},
      { label: "ერთი შეკვრა", value: "1.332 მ²" },
    ],
  },
  {
    _id: "199",
    img: 'https://i.imgur.com/YsU2c8S.png',
    img2: 'https://i.imgur.com/kVX0X00.png',
    img3: 'https://i.imgur.com/M1PI7XT.png',
    productName: "ამსტერდამი შეიდი აგური",
    price: "50.00",
    color: "245х65x7მმ",
    badge: true,
     brand: "Pantum",
    des: "კლინკერის ფილა - ექსტერიერისა და ინტერიერის გაფორმების ნამდვილი ტრენდია.მისი პოპულარობა განპირობებულია ტექსტურის და ფერის მრავალფეროვნებით, ასევე პროდუქტის მაღალი სიმტკიცით და გამძლეობით.",
    cat: "245х65x7მმ",
    pdf: pdf1,
    ficheTech: [
      { label: "ყინვაგამძლეობა", value: "100 სრული ციკლი" },
      { label: "წყალშთანთქვის მაჩვენებელი", value: "0.79–0.85%" },

      { label: "მოცურების მაჩვენებელი", value: "R10 ( 18.30 )" },
      { label: "ცვეთამედეგობა", value: "170.9" },
      
      {label: "მოღუნვის სიმტკიცე", value: "30 N/mm"},
      { label: "ერთი შეკვრა", value: "1.332 მ²" },
    ],
  },
  {
    _id: "200",
    img: 'https://i.imgur.com/PfBGYQd.jpg',
    img2: 'https://i.imgur.com/uaBjWCA.jpg',
    img3: 'https://i.imgur.com/UwXtPUP.jpg',
    productName: "ამსტერდამი 2 აგური",
    price: "38.00",
    color: "245х65x7მმ",
    badge: true,
     brand: "Pantum",
     des: "კლინკერის ფილა - ექსტერიერისა და ინტერიერის გაფორმების ნამდვილი ტრენდია.მისი პოპულარობა განპირობებულია ტექსტურის და ფერის მრავალფეროვნებით, ასევე პროდუქტის მაღალი სიმტკიცით და გამძლეობით.",
     cat: "245х65x7მმ",
    pdf: pdf1,
    ficheTech: [
      { label: "ყინვაგამძლეობა", value: "100 სრული ციკლი" },
      { label: "წყალშთანთქვის მაჩვენებელი", value: "0.79–0.85%" },

      { label: "მოცურების მაჩვენებელი", value: "R10 ( 18.30 )" },
      { label: "ცვეთამედეგობა", value: "170.9" },
      
      {label: "მოღუნვის სიმტკიცე", value: "30 N/mm"},
      { label: "ერთი შეკვრა", value: "1.332 მ²" },
    ],
  },
  {
    _id: "201",
    img: 'https://i.imgur.com/mJ4nNzt.jpg',
    img2: 'https://i.imgur.com/3hefRbq.jpg',
    img3: 'https://i.imgur.com/mnWBugD.jpg',
    productName: "ამსტერდამი 4 აგური",
    price: "37.00",
    color: "245х65x7მმ",
    badge: true,
     brand: "Pantum",
    des: "კლინკერის ფილა - ექსტერიერისა და ინტერიერის გაფორმების ნამდვილი ტრენდია.მისი პოპულარობა განპირობებულია ტექსტურის და ფერის მრავალფეროვნებით, ასევე პროდუქტის მაღალი სიმტკიცით და გამძლეობით.",
    cat: "245х65x7მმ",
    pdf: pdf1,
    ficheTech: [
      { label: "ყინვაგამძლეობა", value: "100 სრული ციკლი" },
      { label: "წყალშთანთქვის მაჩვენებელი", value: "0.79–0.85%" },

      { label: "მოცურების მაჩვენებელი", value: "R10 ( 18.30 )" },
      { label: "ცვეთამედეგობა", value: "170.9" },
      
      {label: "მოღუნვის სიმტკიცე", value: "30 N/mm"},
      { label: "ერთი შეკვრა", value: "1.332 მ²" },
    ],
  },
  {
    _id: "202",
    img: 'https://i.imgur.com/e8NgAjk.jpg',
    img2: 'https://i.imgur.com/kVX0X00.png',
    img3: 'https://i.imgur.com/M1PI7XT.png',
    productName: "ამსტერდამი შეიდი",
    price: "38.00",
    color: "298х298x8მმ",
    badge: true,
     brand: "Pantum",
     des: "კლინკერის ფილა - ექსტერიერისა და ინტერიერის გაფორმების ნამდვილი ტრენდია.მისი პოპულარობა განპირობებულია ტექსტურის და ფერის მრავალფეროვნებით, ასევე პროდუქტის მაღალი სიმტკიცით და გამძლეობით.",
     cat: "298х298x8მმ",
    pdf: pdf1,
    ficheTech: [
      { label: "ყინვაგამძლეობა", value: "100 სრული ციკლი" },
      { label: "წყალშთანთქვის მაჩვენებელი", value: "0.79–0.85%" },

      { label: "მოცურების მაჩვენებელი", value: "R10 ( 18.30 )" },
      { label: "ცვეთამედეგობა", value: "170.9" },
      
      {label: "მოღუნვის სიმტკიცე", value: "30 N/mm"},
      { label: "ერთი შეკვრა", value: "1.332 მ²" },
    ],
  },
  {
    _id: "203",
    img: 'https://i.imgur.com/B24Hxju.jpg',
    img2: 'https://i.imgur.com/nZA1dax.jpg',
    img3: 'https://i.imgur.com/KvFbh9k.jpg',
    productName: "ამსტერდამი 4 საფეხურები",
    price: "32.00",
    color: "298х298x8მმ",
    badge: true,
     brand: "Pantum",
     des: "კლინკერის ფილა - ექსტერიერისა და ინტერიერის გაფორმების ნამდვილი ტრენდია.მისი პოპულარობა განპირობებულია ტექსტურის და ფერის მრავალფეროვნებით, ასევე პროდუქტის მაღალი სიმტკიცით და გამძლეობით.",
    cat: "298х298x8მმ",
    pdf: pdf1,
    ficheTech: [
      { label: "ყინვაგამძლეობა", value: "100 სრული ციკლი" },
      { label: "წყალშთანთქვის მაჩვენებელი", value: "0.79–0.85%" },

      { label: "მოცურების მაჩვენებელი", value: "R10 ( 18.30 )" },
      { label: "ცვეთამედეგობა", value: "170.9" },
      
      {label: "მოღუნვის სიმტკიცე", value: "30 N/mm"},
      { label: "ერთი შეკვრა", value: "1.332 მ²" },
    ],
  },
  {
    _id: "204",
    img: 'https://i.imgur.com/axMcAHP.jpg',
    img2: 'https://i.imgur.com/nZA1dax.jpg',
    img3: 'https://i.imgur.com/KvFbh9k.jpg',
    productName: "ამსტერდამი 4 რელიეფი",
    price: "32.00",
    color: "298х298x8მმ",
    badge: true,
     brand: "Pantum",
     des: "კლინკერის ფილა - ექსტერიერისა და ინტერიერის გაფორმების ნამდვილი ტრენდია.მისი პოპულარობა განპირობებულია ტექსტურის და ფერის მრავალფეროვნებით, ასევე პროდუქტის მაღალი სიმტკიცით და გამძლეობით.",
    cat: "298х298x8მმ",
    pdf: pdf1,
    ficheTech: [
      { label: "ყინვაგამძლეობა", value: "100 სრული ციკლი" },
      { label: "წყალშთანთქვის მაჩვენებელი", value: "0.79–0.85%" },

      { label: "მოცურების მაჩვენებელი", value: "R10 ( 18.30 )" },
      { label: "ცვეთამედეგობა", value: "170.9" },
      
      {label: "მოღუნვის სიმტკიცე", value: "30 N/mm"},
      { label: "ერთი შეკვრა", value: "1.332 მ²" },
    ],
  },
  {
    _id: "205",
    img: 'https://i.imgur.com/p7MYpyX.jpg',
    img2: 'https://i.imgur.com/nZA1dax.jpg',
    img3: 'https://i.imgur.com/KvFbh9k.jpg',
    productName: "ამსტერდამი 4",
    price: "32.00",
    color: "298х298x8მმ",
    badge: true,
     brand: "Pantum",
     des: "კლინკერის ფილა - ექსტერიერისა და ინტერიერის გაფორმების ნამდვილი ტრენდია.მისი პოპულარობა განპირობებულია ტექსტურის და ფერის მრავალფეროვნებით, ასევე პროდუქტის მაღალი სიმტკიცით და გამძლეობით.",
    cat: "298х298x8მმ",
    pdf: pdf1,
    ficheTech: [
      { label: "ყინვაგამძლეობა", value: "100 სრული ციკლი" },
      { label: "წყალშთანთქვის მაჩვენებელი", value: "0.79–0.85%" },

      { label: "მოცურების მაჩვენებელი", value: "R10 ( 18.30 )" },
      { label: "ცვეთამედეგობა", value: "170.9" },
      
      {label: "მოღუნვის სიმტკიცე", value: "30 N/mm"},
      { label: "ერთი შეკვრა", value: "1.332 მ²" },
    ],
  },
  {
    _id: "206",
    img: 'https://i.imgur.com/lalRhSn.jpg',
    img2: 'https://i.imgur.com/fuY23gY.jpg',
    img3: 'https://i.imgur.com/tNNZ8Gg.jpg',
    productName: "ვერმონტი 1",
    price: "44.00",
    color: "298х298x8მმ",
    badge: true,
     brand: "Pantum",
     des: "კლინკერის ფილა - ექსტერიერისა და ინტერიერის გაფორმების ნამდვილი ტრენდია.მისი პოპულარობა განპირობებულია ტექსტურის და ფერის მრავალფეროვნებით, ასევე პროდუქტის მაღალი სიმტკიცით და გამძლეობით.",
    cat: "298х298x8მმ",
    pdf: pdf1,
    ficheTech: [
      { label: "ყინვაგამძლეობა", value: "100 სრული ციკლი" },
      { label: "წყალშთანთქვის მაჩვენებელი", value: "0.79–0.85%" },

      { label: "მოცურების მაჩვენებელი", value: "R10 ( 18.30 )" },
      { label: "ცვეთამედეგობა", value: "170.9" },
      
      {label: "მოღუნვის სიმტკიცე", value: "30 N/mm"},
      { label: "ერთი შეკვრა", value: "1.332 მ²" },
    ],
  },
  {
    _id: "207",
    img: 'https://i.imgur.com/SQk2dal.jpg',
    img2: 'https://i.imgur.com/eRpXr1N.jpg',
    img3: 'https://i.imgur.com/IDlgEmE.jpg',
    productName: "ვერმონტ 1 საფეხური",
    price: "44.00",
    color: "298х298x8მმ",
    badge: true,
    des: "კლინკერის ფილა - ექსტერიერისა და ინტერიერის გაფორმების ნამდვილი ტრენდია.მისი პოპულარობა განპირობებულია ტექსტურის და ფერის მრავალფეროვნებით, ასევე პროდუქტის მაღალი სიმტკიცით და გამძლეობით.",
     brand: "Pantum",
    des: "1/2 ხარისხის ფანერა 3მმ სისქის",
    cat: "245х65x7მმ",
    pdf: pdf1,
    ficheTech: [
      { label: "ყინვაგამძლეობა", value: "100 სრული ციკლი" },
      { label: "წყალშთანთქვის მაჩვენებელი", value: "0.79–0.85%" },

      { label: "მოცურების მაჩვენებელი", value: "R10 ( 18.30 )" },
      { label: "ცვეთამედეგობა", value: "170.9" },
      
      {label: "მოღუნვის სიმტკიცე", value: "30 N/mm"},
      { label: "ერთი შეკვრა", value: "1.332 მ²" },
    ],
  },

];
export const paginationItems7 = [
  {
    _id: "200",
    img: 'https://i.imgur.com/2oOR2D3.png',
    productName: "ლამინირებული ფანერა",
    price: "95.00",
    color: "1250 მმ x 2500 მმ x 18მმ",
    badge: true,
     brand: "Pantum",
     des: "ჩვენ გვსურს შემოგთავაზოთ პრემიუმ ხარისხის ლამინირებული საყალიბე ფანერა, რომელიც გამორჩეულია როგორც მისი ზომებით, ასევე ტექნიკური მახასიათებლებით. ტექნიკური მახასიათებლები: ჩვენი ლამინირებული ფანერას აქვს მაღალი წებოვნების და ფენოლის გარსის ხარისხი რაც ნიშნავს, რომ ფანერის ერთი და იგივე ფილის განმეორებითი გამოყენება არ დააქვეითებს მონოლითური კონსტრუქციების ხარისხს და სათანადო გამაგრების შემთხვევაში არ გამოიწვევს ზედაპირულ ტალღებს.",
     cat: "1250 მმ x 2500 მმ x 18მმ",
    pdf: pdf1,
    ficheTech: [
      { label: "ფილის ფორმატი", value: "1250 x 2500 x 18მმ" },
      { label: "ხარისხი", value: "1/1" },

      { label: "სიმკვრივე", value: "750 კგ/მ3" },
      { label: "ზედაპირი", value: "125 გრ/მ2" },
      
      {label: "ტენიანობა", value: "5-7%"},
      { label: "შეკვრაში", value: "23 ცალი ფილა" },

      {label: "გამოყენების ციკლი", value: "60 +"},
      { label: "შენახვის ვადა", value: "5 წელი" },
    ],
  },
];
export const paginationItems8 = [
  {
    _id: "212",
    img: 'https://i.imgur.com/2oOR2D3.png',
    productName: "ბიოლოგიური გამწმენდი",
    price: "95.00",
    color: "20 კუბური მეტრი",
    badge: true,
     brand: "Pantum",
     des: "ჩვენ გვსურს შემოგთავაზოთ პრემიუმ ხარისხის ლამინირებული საყალიბე ფანერა, რომელიც გამორჩეულია როგორც მისი ზომებით, ასევე ტექნიკური მახასიათებლებით. ტექნიკური მახასიათებლები: ჩვენი ლამინირებული ფანერას აქვს მაღალი წებოვნების და ფენოლის გარსის ხარისხი რაც ნიშნავს, რომ ფანერის ერთი და იგივე ფილის განმეორებითი გამოყენება არ დააქვეითებს მონოლითური კონსტრუქციების ხარისხს და სათანადო გამაგრების შემთხვევაში არ გამოიწვევს ზედაპირულ ტალღებს.",
     cat: "20 კუბური მეტრი",
    pdf: pdf1,
    ficheTech: [
      { label: "", value: "1250 x 2500 x 18მმ" },
      { label: "ხარისხი", value: "1/1" },

      { label: "სიმკვრივე", value: "750 კგ/მ3" },
      { label: "ზედაპირი", value: "125 გრ/მ2" },
      
      {label: "ტენიანობა", value: "5-7%"},
      { label: "შეკვრაში", value: "23 ცალი ფილა" },

      {label: "გამოყენების ციკლი", value: "60 +"},
      { label: "შენახვის ვადა", value: "5 წელი" },
    ],
  },
  {
    _id: "213",
    img: 'https://i.imgur.com/2oOR2D3.png',
    productName: "ბიოლოგიური გამწმენდი",
    price: "95.00",
    color: "20 კუბური მეტრი",
    badge: true,
     brand: "Pantum",
     des: "ჩვენ გვსურს შემოგთავაზოთ პრემიუმ ხარისხის ლამინირებული საყალიბე ფანერა, რომელიც გამორჩეულია როგორც მისი ზომებით, ასევე ტექნიკური მახასიათებლებით. ტექნიკური მახასიათებლები: ჩვენი ლამინირებული ფანერას აქვს მაღალი წებოვნების და ფენოლის გარსის ხარისხი რაც ნიშნავს, რომ ფანერის ერთი და იგივე ფილის განმეორებითი გამოყენება არ დააქვეითებს მონოლითური კონსტრუქციების ხარისხს და სათანადო გამაგრების შემთხვევაში არ გამოიწვევს ზედაპირულ ტალღებს.",
     cat: "20 კუბური მეტრი",
    pdf: pdf1,
    ficheTech: [
      { label: "", value: "1250 x 2500 x 18მმ" },
      { label: "ხარისხი", value: "1/1" },

      { label: "სიმკვრივე", value: "750 კგ/მ3" },
      { label: "ზედაპირი", value: "125 გრ/მ2" },
      
      {label: "ტენიანობა", value: "5-7%"},
      { label: "შეკვრაში", value: "23 ცალი ფილა" },

      {label: "გამოყენების ციკლი", value: "60 +"},
      { label: "შენახვის ვადა", value: "5 წელი" },
    ],
  },
  {
    _id: "214",
    img: 'https://i.imgur.com/2oOR2D3.png',
    productName: "ბიოლოგიური გამწმენდი",
    price: "95.00",
    color: "20 კუბური მეტრი",
    badge: true,
     brand: "Pantum",
     des: "ჩვენ გვსურს შემოგთავაზოთ პრემიუმ ხარისხის ლამინირებული საყალიბე ფანერა, რომელიც გამორჩეულია როგორც მისი ზომებით, ასევე ტექნიკური მახასიათებლებით. ტექნიკური მახასიათებლები: ჩვენი ლამინირებული ფანერას აქვს მაღალი წებოვნების და ფენოლის გარსის ხარისხი რაც ნიშნავს, რომ ფანერის ერთი და იგივე ფილის განმეორებითი გამოყენება არ დააქვეითებს მონოლითური კონსტრუქციების ხარისხს და სათანადო გამაგრების შემთხვევაში არ გამოიწვევს ზედაპირულ ტალღებს.",
     cat: "20 კუბური მეტრი",
    pdf: pdf1,
    ficheTech: [
      { label: "", value: "1250 x 2500 x 18მმ" },
      { label: "ხარისხი", value: "1/1" },

      { label: "სიმკვრივე", value: "750 კგ/მ3" },
      { label: "ზედაპირი", value: "125 გრ/მ2" },
      
      {label: "ტენიანობა", value: "5-7%"},
      { label: "შეკვრაში", value: "23 ცალი ფილა" },

      {label: "გამოყენების ციკლი", value: "60 +"},
      { label: "შენახვის ვადა", value: "5 წელი" },
    ],
  },
];
export const paginationItems9 = [
  {
    _id: "1",
    img: 'https://i.imgur.com/k5JhhxA.png',
    productName: "ორიენტირებული ფილა OSB 9მმ",
    price: "24.00",
    color: "244cm x 122cm x 9მმ",
    badge: true,
     brand: "Pantum",
     des: "ორიენტირებული ბურბუშელოვანი ფილა OSB, სამშენებლო მასალა რომელიც წარმოადგენს ხის ანათალისა და წყალგამძლე შემკვრელის დაწნეხილ მრავალფენიან ფილას",
     cat: "2440 x 1220მმ",
    pdf: pdf1,
    ficheTech: [
      { label: "ზომა", value: "2440 x 1220მმ" },
      { label: "სისქე", value: "9მმ" },

    ],
  },
  {
    _id: "213",
    img: 'https://i.imgur.com/k5JhhxA.png',
    productName: "ორიენტირებული ფილა OSB 12მმ",
    price: "34.00",
    color: "244cm x 122cm x 12მმ",
    badge: true,
     brand: "Pantum",
     des: "ორიენტირებული ბურბუშელოვანი ფილა OSB, სამშენებლო მასალა რომელიც წარმოადგენს ხის ანათალისა და წყალგამძლე შემკვრელის დაწნეხილ მრავალფენიან ფილას",
     cat: "2440 x 1220მმ",
    pdf: pdf1,
    ficheTech: [
      { label: "ზომა", value: "2440 x 1220მმ" },
      { label: "სისქე", value: "12მმ" },
    ],
  },
  {
    _id: "2104",
    img: 'https://i.imgur.com/k5JhhxA.png',
    productName: "ორიენტირებული ფილა OSB 15მმ",
    price: "43.00",
    color: "244cm x 122cm x 15მმ",
    badge: true,
     brand: "Pantum",
     des: "ორიენტირებული ბურბუშელოვანი ფილა OSB, სამშენებლო მასალა რომელიც წარმოადგენს ხის ანათალისა და წყალგამძლე შემკვრელის დაწნეხილ მრავალფენიან ფილას",
     cat: "2440 x 1220მმ",
    pdf: pdf1,
    ficheTech: [
      { label: "ზომა", value: "2440 x 1220მმ" },
      { label: "სისქე", value: "15მმ" },
    ],
  },
  {
    _id: "2104",
    img: 'https://i.imgur.com/k5JhhxA.png',
    productName: "ორიენტირებული ფილა OSB 18მმ",
    price: "46.00",
    color: "244cm x 122cm x 18მმ",
    badge: true,
     brand: "Pantum",
     des: "ორიენტირებული ბურბუშელოვანი ფილა OSB, სამშენებლო მასალა რომელიც წარმოადგენს ხის ანათალისა და წყალგამძლე შემკვრელის დაწნეხილ მრავალფენიან ფილას",
     cat: "2440 x 1220მმ",
    pdf: pdf1,
    ficheTech: [
      { label: "ზომა", value: "2440 x 1220მმ" },
      { label: "სისქე", value: "18მმ" },
    ],
  },
];

export const paginationItems19 = [

  {
    _id: "2104",
    img: 'https://i.imgur.com/TJ1yh5G.jpg',
    productName: "ფანერა III/IV 1220x2440x15მმ",
    price: "43.00",
    color: "244cm x 122cm x 15მმ",
    badge: true,
     brand: "Pantum",
     des: "ორიენტირებული ბურბუშელოვანი ფილა OSB, სამშენებლო მასალა რომელიც წარმოადგენს ხის ანათალისა და წყალგამძლე შემკვრელის დაწნეხილ მრავალფენიან ფილას",
     cat: "2440 x 1220მმ",
    pdf: pdf1,
    ficheTech: [
      { label: "ზომა", value: "2440 x 1220მმ" },
      { label: "სისქე", value: "15მმ" },
    ],
  },
  {
    _id: "2104",
    img: 'https://i.imgur.com/TJ1yh5G.jpg',
    productName: "ფანერა III/IV 1220x2440x18მმ",
    price: "46.00",
    color: "244cm x 122cm x 18მმ",
    badge: true,
     brand: "Pantum",
     des: "ორიენტირებული ბურბუშელოვანი ფილა OSB, სამშენებლო მასალა რომელიც წარმოადგენს ხის ანათალისა და წყალგამძლე შემკვრელის დაწნეხილ მრავალფენიან ფილას",
     cat: "2440 x 1220მმ",
    pdf: pdf1,
    ficheTech: [
      { label: "ზომა", value: "2440 x 1220მმ" },
      { label: "სისქე", value: "18მმ" },
    ],
  },
];
// const images = [
//   currentProduct?.productImage,
//   currentProduct?.productImage2,
//   currentProduct?.productImage3,
// ];

// const nextSlide = () => {
//   setCurrent(current === images.length - 1 ? 0 : current + 1);
// };

// const prevSlide = () => {
//   setCurrent(current === 0 ? images.length - 1 : current - 1);
// };

// return (
//   <div className="singleProductPage">
//     <div className="flexDivv"> 
//       <div className="carousel">
//         <button onClick={prevSlide} style={{background: 'none', border: '0'}}><FontAwesomeIcon style={{width: '30px', height: '30px'}} icon={faChevronLeft} /></button>
//         <img src={images[current]} alt="Product" />
//         <button onClick={nextSlide}style={{background: 'none', border: '0'}}><FontAwesomeIcon style={{width: '30px', height: '30px'}} icon={faChevronRight} /></button>
//       </div>
