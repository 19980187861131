// src/components/home/Header/HeaderBottom.js
import React, { useState, useRef, useEffect } from "react";
import { HiOutlineMenuAlt4 } from "react-icons/hi";
import { FaSearch, FaUser, FaCaretDown, FaShoppingCart } from "react-icons/fa";
import Flex from "../../designLayouts/Flex";
import { Link, useNavigate, Route, Routes } from "react-router-dom";
import { useSelector } from "react-redux";
import { paginationItems, paginationItems2, paginationItems3, paginationItems4, paginationItems5, paginationItems6 } from "../../../constants";
import Sidebarr from "../../sidebar/sidebar";
import '../../sidebar/sidebar.css';
import CustomSearchBar from "../SearchBar/SearchBar";
const HeaderBottom = () => {
  const products = useSelector((state) => state.orebiReducer.products);
  const [show, setShow] = useState(false);
  const [showUser, setShowUser] = useState(false);
  const navigate = useNavigate();
  const ref = useRef();

  useEffect(() => {
    document.body.addEventListener("click", (e) => {
      if (ref.current.contains(e.target)) {
        setShow(true);
      } else {
        setShow(false);
      }
    });
  }, [show, ref]);

  const data = [
    { name: "ფანერა 1/2 ხარისხი", image: "https://i.imgur.com/uCk1O4o.jpg", route: "/fanera" },
    { name: "ფანერა 2/3 ხარისხი", image: "https://i.imgur.com/4m1t8rc.jpg", route: "/fanera23" },
    { name: "ფანერა 3/4 ხარისხი", image: "https://i.imgur.com/Ifw13jV.jpg", route: "/fanera34" },
    { name: "ლამინირებული ფანერა", image: "https://i.imgur.com/2oOR2D3.png", route: "/sayalibe" },
    { name: "ლამინირებული იატაკი", image: "https://i.imgur.com/7And5U0.jpg", route: "/laminati" },
    { name: "კერამოგრანიტი", image: "https://i.imgur.com/wOZj09m.jpg", route: "/keramograniti" },
    { name: "კლინკერის ფილა", image: "https://i.imgur.com/YsU2c8S.png", route: "/klinkeri" },
  ];

  return (
    <div className="w-full bg-[#2F4F4F] relative">
      <div className="max-w-container mx-auto">
        <Flex style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}>
          <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', paddingTop: '2%', paddingBottom: '1.3%' }}>
            <div style={{ width: '15%', maxWidth: '50px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }} className="gap-4 mt-2 lg:mt-0 items-center pr-6 cursor-pointer relative">
              <Sidebarr />
              <h2 className="tishi" style={{ color: 'white', fontSize: "20px", marginBottom: '25px', fontWeight: "900" }}>EUROWOOD</h2>
            </div>
            <CustomSearchBar placeholder="მოძებნეთ პროდუქტი" data={data} />
        
            <Link to="/cart">
              <div className="relative" style={{ marginRight: '20px' }}>
                <FaShoppingCart style={{ color: 'white', marginTop: '5px' }} />
                <span className="absolute font-titleFont top-3 -right-2 text-xs w-4 h-4 flex items-center justify-center rounded-full bg-white text-black">
                  {products.length > 0 ? products.length : 0}
                </span>
              </div>
            </Link>
          </div>
        </Flex>
      </div>
    </div>
  );
};

export default HeaderBottom;
