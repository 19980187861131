import React, { useState, useEffect } from "react";
import { FaArrowAltCircleLeft, FaArrowAltCircleRight } from "react-icons/fa";
import { Link } from "react-router-dom";
import './carousel.css'
export const SliderData = [
  { 
    image: 'https://i.imgur.com/H4RgP97.png',
    url: "/klinkeri",
    },
    {
    image: 'https://i.imgur.com/SOEGfDT.png',
    url: "/fanera",
    },
    {
    image: 'https://i.imgur.com/U6PXfer.png',
    url: "/laminati",
    },
]
export const BannerSlider = ({ slides }) => {
  const [current, setCurrent] = useState(0);
  const length = slides.length;

  const nextSlide = () => {
    setCurrent(current === length - 1 ? 0 : current + 1);
  };

  const prevSlide = () => {
    setCurrent(current === 0 ? length - 1 : current - 1);
  };

  useEffect(() => {
    const interval = setInterval(nextSlide, 5000); // Change slide every 3 seconds
    return () => clearInterval(interval); // Clear interval on component unmount
  }, [current]); // Adding 'current' to dependency array to update effect

  if (!Array.isArray(slides) || slides.length <= 0) {
    return null;
  }

  return (
    <section className='slider'>
      <FaArrowAltCircleLeft className='left-arrow' onClick={prevSlide} />
      <FaArrowAltCircleRight className='right-arrow' onClick={nextSlide} />
      {slides.map((slide, index) => {
        return (
          <Link
            to={slide.url}
            className={index === current ? 'slide active' : 'slide'}
            key={index}
          >
            {index === current && (
              <img src={slide.image} alt='carousel image' className='image' />
            )}
          </Link>
        );
      })}
    </section>
  )
}
