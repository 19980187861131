import { Pagination3, Pagination7 } from "../../components/pageProps/shopPage/Pagination";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Breadcrumbs from "../../components/pageProps/Breadcrumbs";
import ProductInfo from "../../components/pageProps/productDetails/ProductInfo";
import { FaDownload } from "react-icons/fa";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import "./../ProductDetails/productDetails.css"
import { Link } from "react-router-dom";
export default function Pompebi() {
    const [itemsPerPage, setItemsPerPage] = useState(48);
  const itemsPerPageFromBanner = (itemsPerPage) => {
    setItemsPerPage(itemsPerPage);
  };
  const location = useLocation();
  const [prevLocation, setPrevLocation] = useState("");
  const [productInfo, setProductInfo] = useState([]);
  const [current, setCurrent] = useState(0);

    return(
        <div className="w-full mx-auto border-b-[1px] border-b-gray-300">
    <div className="max-w-container mx-auto px-4">
      <div style={{marginTop: '60px'}} className="xl:-mt-10 -mt-7">
        {/* <Breadcrumbs title="" prevLocation={prevLocation} /> */}
      </div>
      <div className="w-full grid grid-cols-1 md:grid-cols-2 xl:grid-cols-6 gap-4 h-full -mt-5 xl:-mt-8 pb-10 bg-gray-100 p-4">
          <div className="h-full xl:col-span-2">
            <div className='butts' >
                <img 
                  className="w-full h-full"
                  src="https://i.imgur.com/cmjDZ1U.png"
                />
            </div>
        </div>
        <div className="h-full w-full md:col-span-2 xl:col-span-4 xl:px-4 flex flex-col gap-6 justify-center">
        <div className="flex flex-col gap-5">
            <h2 className="text-4xl font-semibold">ჰაერის კომპრესორები</h2>
            <p className="text-1xl font-semibold">
            ჰაერის კომპრესორები ნომინალური სიმძლავრით 45-დან 150 ლიტრამდე წუთში საშუალებას გაძლევთ ეფექტურად მიაწოდოთ ჰაერი აეროტენკში , ბიოლოგიურ სადგურებში, ბიოფილტრებში, აირლიფტების სისტემებში, ასევე აკვარიუმებსა და რეზერვუარებში. კომპრესორებს აქვთ კომპაქტური ფორმა და დაბალი ენერგიის მოხმარება, რაც მათ ყველაზე ენერგოეფექტურ გადაწყვეტილებად აქცევს.            
            <br />
            <br />
            <br />
            PA 60 - 450  ლარი <br />
            PA 80 - 500 ლარი <br />
            PA 150 - 800 ლარი
            </p>
            <p className="text-base text-green-600 font-medium">მარაგშია</p>
          </div>
        </div>
      </div>
      </div>

      
    </div>
    )
 }
